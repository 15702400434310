























































import Class from './c-indicator';
export default Class;
