import { Component, Prop, Vue } from 'vue-property-decorator';

import '../../initialization';

import VariablePie from './variable-pie';

@Component({
    components: {
        'variable-pie': VariablePie
    }
})
export default class ChartVariablePie extends Vue {
    @Prop({
        type: Boolean,
        required: false,
        default: false
    })
    public readonly debug!: boolean;

    @Prop({
        type: Array,
        required: true
    })
    public readonly data!: any[];

    @Prop()
    public readonly redrawTrigger!: any;

    public percent = '';

    private created() {
        this.$watch('data', this.getPercent);
    }

    public mounted() {
        this.getPercent();
    }

    public getPercent() {
        let sum = 0;
        this.data.forEach((item) => {
            sum += item.y;
        });
        this.percent = ((this.data[1].y / sum) * 100).toFixed(2);
    }
}