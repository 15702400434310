import { render, staticRenderFns } from "./ProgramLinkList.vue?vue&type=template&id=220c8e26&scoped=true&"
import script from "./ProgramLinkList.vue?vue&type=script&lang=ts&"
export * from "./ProgramLinkList.vue?vue&type=script&lang=ts&"
import style0 from "./ProgramLinkList.vue?vue&type=style&index=0&id=220c8e26&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "220c8e26",
  null
  
)

export default component.exports