<template>
    <b-container fluid="true">
        <div v-for="(item, ind) in recipData" :key="ind">
            <receipts-graph-row :value="item.value" :text="item.text" :code="item.code" :data-value="item.dataValue"
                                :color="item.color" :background-color="item.backgroundColor"
                                v-on:collapse-on="collapseOn(item, ind)"
                                v-on:collapse-off="collapseOff(item, ind)"></receipts-graph-row>
            <b-collapse :id="'collapse-'+ind">
                <div v-for="(subitem, subind) in recipData" :key="subind">
                <receipts-graph-row :value="subitem.value" :text="subitem.text" :code="subitem.code" :data-value="subitem.dataValue"
                                    :color="item.color" :background-color="item.backgroundColor" is-child=True
                                   ></receipts-graph-row>
                </div>
            </b-collapse>
        </div>

    </b-container>
</template>

<script>

import ReceiptsGraphRow from './ReceiptsGraphRow'

export default {
    name: 'ReceiptsGraph',
    components: {ReceiptsGraphRow},
    data () {
        return {
            value: 85,
            recipData: [
                {
                    code: '101101',
                    text: 'Поступления налогов',
                    value: 88.8,
                    dataValue: 15489763,
                    color: '#42a5f5',
                    backgroundColor: '#d9edfd'
                },
                {
                    code: '101102',
                    text: 'Поступления налогов',
                    value: 66.8,
                    dataValue: 465465,
                    color: '#ef5350',
                    backgroundColor: '#fbdcdc'
                },
                {
                    code: '101102',
                    text: 'Поступления налогов',
                    value: 55.8,
                    dataValue: 465465,
                    color: '#8d6e63',
                    backgroundColor: '#e8e2df'
                },
                {
                    code: '101102',
                    text: 'Поступления налогов',
                    value: 44.8,
                    dataValue: 465465,
                    color: '#ab47bc',
                    backgroundColor: '#eedaf1'
                },
                {
                    code: '101102',
                    text: 'Поступления налогов',
                    value: 33.8,
                    dataValue: 465465,
                    color: '#9ccc65',
                    backgroundColor: '#ebf4e0'
                },
                {
                    code: '101102',
                    text: 'Поступления налогов',
                    value: 22.8,
                    dataValue: 465465,
                    color: '#ec407a',
                    backgroundColor: '#fbd8e4'
                },
                {
                    code: '101102',
                    text: 'Поступления налогов',
                    value: 11.8,
                    dataValue: 465465,
                    color: '#ffc675',
                    backgroundColor: '#ffefd9'
                }
            ]
        }
    },
    methods: {
        collapseOn (item, ind) {
            this.$root.$emit('bv::toggle::collapse', 'collapse-'+ind)
        },
        collapseOff (item, ind) {
            this.$root.$emit('bv::toggle::collapse', 'collapse-'+ind)
        }
    }
}
</script>

<style scoped lang="scss">

</style>