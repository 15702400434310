











































import { Component, Vue, Prop } from 'vue-property-decorator';
import ChartVariablePie from '@/modules/widget-yard/c-widget/chart-variable-pie';
import VariablePie from '@/modules/widget-yard/c-widget/chart-variable-pie/variable-pie';


@Component({
    name: 'c-prog-fin',
    components: {
        'chart-variable-pie': ChartVariablePie,
        'variable-pie': VariablePie
    }
})
export default class CProgramFinance extends Vue {
    @Prop({
        required: true,
        default: 0
    })
    private progId!: number | null;

    private index = 0;
    private value = 0;

    private finances = [
        { name: '2016 год', y: 114523.8 },
        { name: '2017 год', y: 102270.1 },
        { name: '2018 год', y: 70953.7 },
        { name: '2019 год', y: 103931.1 },
        { name: '2020 год', y: 117860 }
    ];

    private get finData(): any[] {
        if (this.progId === 0) { // 'Программа развития регионов на 2016-2020 годы'
            return [
                { col1: '2016 год', col2: '114 523,8 млн. тенге' },
                { col1: '2017 год', col2: '102 270,1 млн. тенге' },
                { col1: '2018 год', col2: '70 953,7 млн. тенге' },
                { col1: '2019 год', col2: '103 931,1 млн. тенге' },
                { col1: '2020 год', col2: '117 860 млн. тенге' },
                { col1: 'Всего', col2: '509 538,7 млн. тенге' }
            ];
        }
        return [];
    }

    private get datas(): any[] {
        const arr: any[] = [];
        let i = 0;
        for (const item of this.finances) {
            if (this.index === i) {
                arr.push({ name: 'Всего', y: (509738.7 - item.y) });
                arr.push(item);
                this.value = item.y;
                return arr;
            }
            i++;
        }
        return arr;
    }
}


