



















































































































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
    name: 'c-prog-filter'
})
export default class ProgramFilter extends Vue {
    @Prop({
        required: true,
        default: []
    })
    private directionLst!: any[];

    @Prop({
        required: true,
        default: null
    })
    private curDirection: any | null;

    @Prop({
        required: true,
        default: []
    })
    private sphereLst!: any[];

    @Prop({
        required: true,
        default: null
    })
    private curSphere!: any | null;

    @Prop({
        required: true,
        default: null
    })
    private yearLst!: any | null;

    @Prop({
        required: true,
        default: null
    })
    private curYear!: any | null;

    @Prop({
        required: false,
        default: false
    })
    private isEvent!: boolean;

    @Prop({
        required: false,
        default: null
    })
    private katoLst!: any[] | null;

    @Prop({
        required: false,
        default: null
    })
    private chartVisible!: boolean | null;


    // ----kato
    private curKato: any | null = null;
    // ----

    private get visible(): null | boolean {
        return this.chartVisible;
    }

    private set visible(val: null | boolean) {
        this.$emit('chgVisible', val);
    }

    private get curDir(): any | null {
        return this.curDirection;
    }

    private set curDir(val: any | null) {
        this.$emit('chgCurDir', val);
    }

    private get curSph(): any | null {
        return this.curSphere;
    }

    private set curSph(val: any | null) {
        this.$emit('chgCurSph', val);
    }

    private get curY(): any | null {
        return this.curYear;
    }

    private set curY(val: any | null) {
        this.$emit('chgCurY', val);
    }

    private makeToast(variant: any, title: string, tostbody: any) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }

    private setNameLang(obj: any, codeName?: any | null): any {
        let txt = obj['name_' + this.$i18n.locale];
        if (txt === undefined) { txt = obj.name_ru; }
        if (codeName !== undefined && codeName !== null && obj[codeName] !== undefined) {
            txt = obj[codeName] + ' - ' + txt;
        }
        const el = Object.assign({}, obj);
        el.name = txt;
        return el;
    }

    private created() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;
        this.$watch('curKato', () => {
            that.$emit('chgKato', that.curKato);
        });
    }
}
