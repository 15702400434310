













































import { Component, Vue, Prop } from 'vue-property-decorator';
import ProgramFilter from '@/modules/program-monitor2/components/program-filter.vue';
import BTablePag from '@/modules/program-monitor2/components/b-table-pag.vue';
import CChartWidget from '@/modules/program-monitor2/components/charts/c-chart-widget.vue';

@Component({
    name: 'c-prog-fin',
    components: {
        'c-prog-filter': ProgramFilter,
        'b-table-pag': BTablePag,
        'c-widg-chart': CChartWidget
    }
})
export default class ProgramFinance extends Vue {
    @Prop({
        required: true,
        default: { id: -1, name: '' }
    })
    private curProg!: any;

    @Prop({
        required: true,
        default: null
    })
    private directBaseData!: any | null;

    @Prop({
        required: true,
        default: null
    })
    private sphereData!: any | null;

    @Prop({
        required: true,
        default: false
    })
    private active!: boolean;

    // --------- diagram
    private diagramData1: any[] | null = null;
    private diagramAll1: string | null = null;

    private reflowTrig = 1; // значения 1 и -1 отслеживается изменение
    private chartVisible = false;

    private diagramData2: any[] | null = null;
    private diagramColor2: string[] = [];
    // ------------

    private fields = [
        {
            key: 'event',
            label: 'Мероприятия',
            sortable: true
        },
        {
            key: 'unitName', //
            label: 'Единицы измерения',
            sortable: true
        },
        {
            key: 'plan',
            label: 'План',
            sortable: true
        },
        {
            key: 'fact',
            label: 'Факт',
            sortable: true
        },
        {
            key: 'percentRound',
            label: 'Процент освоения',
            sortable: true
        },
        {
            key: 'source',
            label: 'Источник финансирования',
            sortable: true
        }
    ];

    private curDirection: any | null = null;
    private curSphere: any | null = null;
    private yearLst: any[] = [];
    private curYear: any | null = null;

    private finBaseData: any | null = null; // данные по финансированию из базы
    private sortBy = 'event';
    private sortDesc = false;
    private curPage = 1;
    private perPage = 10;
    private pgRows = 0;

    private get directionLst(): any[] {
        if (this.directBaseData === null) { return []; }
        return this.getDictLangArr(this.directBaseData, 'direction', this.finBaseData);
    }

    private get sphereLst(): any[] {
        if (this.sphereData === null) { return []; }
        return this.getDictLangArr(this.sphereData, 'sphere', this.finData);
    }

    private getDictLangArr(data: any[], fieldName: string, finData: any[]) { // получение массива по справочникам с учётом языка
        const dictArr: any[] = [];
        for (const el of data) {
            const newObj: any = Object.assign({}, el);
            if (this.$i18n.locale === 'kk') { newObj.name = newObj.name_kz; } else { newObj.name = newObj['name_' + this.$i18n.locale]; }
            newObj.src = '';
            dictArr.push(newObj);
        }

        const res = [];
        if (finData !== null) {
            for (const el of finData) {
                if (el[fieldName] !== undefined && el[fieldName] !== null) {
                    for (let i = 0; i < dictArr.length; i++) {
                        const d = dictArr[i];
                        if (d.id === el[fieldName].id) {
                            res.push(d);
                            dictArr.splice(i, 1);
                            break;
                        }
                    }
                }
            }
            return res;
        }
        return dictArr;
    }

    private chgCurDir(val: null | any) {
        this.curSphere = null;
        this.curDirection = val;
    }
    private chgCurSph(val: null | any) {
        this.curSphere = val;
    }
    private chgCurY(val: null | any) {
        this.curYear = val;
    }

    private mounted() {
        // eslint-disable-next-line
        const that = this;
        this.loadFinData();
        this.$watch('curProg', this.loadFinData);
        this.$watch('active', function () {
            if (that.active) {
                that.reflowTrig = that.reflowTrig * (-1);
            }
        });
    }

    private get finData(): any[] {
        this.pgRows = 0;
        this.diagramData1 = null;
        this.diagramData2 = null;
        this.diagramColor2 = ['#459ED8', '#bd7d1e', '#35b345', '#511dca', '#b960aa', '#22608a', '#228a5f', '#e6e366'];
        if (this.finBaseData === null || this.finBaseData.length === 0) { return []; }
        let allFact = 0;
        let mastered = 0;
        let unitName = '';
        const sourceMap = new Map();
        const result = [];
        for (const el of this.finBaseData) {
            const obj = Object.assign({ unitName: '', percentRound: null }, el);
            if (this.checkFilter(obj)) {
                if (el.statUnit !== null) {
                    if (this.$i18n.locale === 'kk') { obj.unitName = el.statUnit.name_kz; } else { obj.unitName = el.statUnit['name_' + this.$i18n.locale]; }
                    if (unitName === '') {
                        unitName = el.statUnit.nationalSymbol;
                    }
                }
                if (el.fact !== null && el.plan !== null) {
                    if (obj.plan !== 0) { obj.percentRound = Math.round(el.fact / el.plan * 10000) / 100; }
                    if (obj.percentRound >= 100) { obj._cellVariants = { percentRound: 'progress-reach' }; } else { obj._cellVariants = { percentRound: 'progress-not-reach' }; }
                    allFact += el.fact;
                }
                if (obj.percentRound >= 100) { mastered++; }
                if (el.source !== null && el.source !== undefined) {
                    let val = 1;
                    if (sourceMap.has(el.source)) {
                        val = sourceMap.get(el.source) + 1;
                    }
                    sourceMap.set(el.source, val);
                }
                result.push(obj);
            }
        }
        if (sourceMap.size > 0) {
            this.diagramData2 = [];
            for (const [key, value] of sourceMap) {
                this.diagramData2.push({ name: key, y: value });
            }
            if (this.diagramData2.length > this.diagramColor2.length) { this.diagramColor2.length = this.diagramData2.length; }
        }
        this.diagramData1 = [{ name: 'Освоено', y: mastered }, { name: 'Не освоено', y: (result.length - mastered) }];
        this.diagramAll1 = '<span>' + String(Math.round(allFact * 10) / 10) + '</span><br/><span style="font-size: 1rem; text-align: center">' + unitName + '</span>';
        this.pgRows = result.length;
        return result;
    }

    private checkFilter(obj: any) {
        if (this.curSphere !== null) {
            if (obj.sphere === null) { return false; }
            if (obj.sphere.id !== this.curSphere.id) { return false; }
        }
        if (this.curDirection !== null) {
            if (obj.direction === null) { return false; }
            if (obj.direction.id !== this.curDirection.id) { return false; }
        }
        if (this.curYear !== null && this.curYear.val !== null) {
            if (obj.year !== this.curYear.val) { return false; }
        }
        return true;
    }

    private async loadFinData() {
        this.diagramData1 = null;
        this.curPage = 1;
        this.finBaseData = await fetch('/api/forecast/events/financing/view?program=' + this.curProg.id)
            .then(response => response.json());
        this.getYearLst(this.finBaseData);
    }

    private getYearLst(data: any[]) { // получение списка годов из данных
        const result = [];
        this.yearLst = [];
        for (const el of data) {
            if (el.year !== null) { result.push(el.year); }
        }
        result.sort(function (d1: number, d2: number) { return (d1 - d2); });
        for (const el of result) {
            if (this.yearLst.length < 1 || this.yearLst[this.yearLst.length - 1].val !== el) {
                this.yearLst.push({ val: el });
            }
        }
        if (this.yearLst.length > 0) { this.curYear = this.yearLst[this.yearLst.length - 1]; } else { this.curYear = null; }
    }
}
