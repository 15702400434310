

















import { Vue, Component, Prop } from 'vue-property-decorator';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import Highcharts3D from 'highcharts/highcharts-3d';


HighchartsMore(Highcharts);
Highcharts3D(Highcharts);


@Component({
})
export default class CProgPie extends Vue {
    @Prop({
        required: true,
        default: null
    })
    private data!: any[] | null;

    @Prop({
        required: true,
        default: []
    })
    private color!: string[];

    @Prop({
        required: false,
        default: null
    })
    private all!: string | null;

    @Prop({
        type: String,
        required: false,
        default: ''
    })
    public readonly download!: string;

    @Prop({
        required: false,
        default: 1
    })
    public readonly reflowTrig!: number;

    private circleTxt: null | Highcharts.SVGElement = null;


    private get allStr(): string {
        if (this.all !== null) { return this.all; }
        if (this.data === null) { return ''; }
        let sum = 0;
        for (const el of this.data) {
            sum = sum + el.y;
        }
        return String(sum);
    }

    private readonly redrawTrigger!: any;

    private chart: Highcharts.Chart | undefined;
    private options: Highcharts.Options = {};

    private created() {
        this.$watch('data', this.dataChanged);
        this.$watch('percent', this.dataChanged);
        this.$watch('reflowTrig', this.reflowTrigChg);
    }

    private getData(): null | any {
        if (this.data === null) { return null; }
        const data = [];
        const color = [];
        for (let i = 0; i < this.data.length; i++) {
            if (this.data[i].y !== 0) {
                data.push(this.data[i]);
                if (i < this.color.length) { color.push(this.color[i]); }
            }
        }
        return { data: data, color: color };
    }

    private mounted() {
        this.dataChanged();
        this.$watch('download', this.downloadAction);
    }

    private beforeDestroy() {
        if (this.chart !== undefined) {
            this.chart.destroy();
        }
    }

    private reflowTrigChg() {
        if (this.chart !== undefined) {
            // this.chart.reflow();
            this.dataChanged();
        }
    }

    private updateOptions() {
        if (this.data !== null && this.data !== undefined) {
            const getData = this.getData();
            const colors = getData.color;
            const data = getData.data;

            this.options = {
                colors,
                chart: {
                    type: 'variablepie',
                    backgroundColor: 'transparent',
                    spacing: [0, 0, 0, 0],
                    height: 250,
                    style: {
                        'float': 'right'
                    }
                },
                /* title: {
                    verticalAlign: 'middle',
                    useHTML: true,
                    // align: 'left',
                    x: -100,
                    floating: false,
                    text: this.allStr,
                    style: {
                        color: '#223345',
                        fontSize: '1.4rem'
                    }
                }, */
                title: {
                    text: undefined
                },
                exporting: {
                    enabled: false,
                    menuItemDefinitions: {
                        printChart: {
                            text: 'Печать'
                        },
                        downloadPNG: {
                            text: 'Скачать PNG'
                        },
                        downloadJPEG: {
                            text: 'Скачать JPEG'
                        },
                        downloadPDF: {
                            text: 'Скачать PDF'
                        },
                        downloadSVG: {
                            text: 'Скачать SVG'
                        }
                    }
                },
                plotOptions: {
                    variablepie: {
                        center: [100, '50%'],
                        allowPointSelect: true,
                        // borderWidth: 0,
                        cursor: 'pointer',
                        /* dataLabels: {
                            alignTo: 'toPlotEdges',
                            // connectorShape: 'crookedLine',
                            // crookDistance: '100%',
                            crop: false,
                            enabled: true,
                            format: '<span style="color:{point.color}">{point.y}</span>',
                            padding: 0,
                            style: {
                                fontSize: '1.2rem',
                                fontWeight: '300',
                                color: 'contrast',
                                textOutline: '0 contrast'
                            },
                            overflow: 'allow'
                        }, */
                        showInLegend: true
                    },
                    series: {
                        animation: false
                    }
                },
                legend: {
                    layout: 'vertical',
                    align: 'right',
                    verticalAlign: 'top',
                    floating: true,
                    itemStyle: {
                        fontWeight: '100',
                        fontSize: '12px',
                        textOverflow: 'null'
                    },
                    navigation: {
                        enabled: false
                    },
                    labelFormat: '<b>{y}</b>  <b>{name}</b>',
                    symbolRadius: 0
                },
                series: [{
                    type: 'variablepie',
                    innerSize: '80%',
                    size: '90%',
                    data: data,
                    dataLabels: {
                        enabled: false
                    }
                }],
                tooltip: {
                    headerFormat: '',
                    pointFormat: '<span style="color:{point.color}">\u25CF</span><b>{point.name}</b>  <b>{point.y}</b>'
                },
                credits: {
                    enabled: false
                }
            };
        }
    }

    private downloadAction() {
        if (this.chart !== undefined) {
            if (this.download === 'print') {
                this.chart.print();
            }
            if (this.download === 'pdf') {
                this.chart.exportChart({
                    type: 'application/pdf'
                }, {});
            }
            if (this.download === 'jpeg') {
                this.chart.exportChart({
                    type: 'image/jpeg'
                }, {});
            }
            if (this.download === 'png') {
                this.chart.exportChart({
                    type: 'image/png'
                }, {});
            }
            if (this.download === 'svg') {
                this.chart.exportChart({
                    type: 'image/svg+xml'
                }, {});
            }
        }
    }

    private dataChanged() {
        this.updateOptions();
        if (this.chart === undefined) {
            this.chart = Highcharts.chart(this.$el as HTMLElement, this.options);
        } else {
            this.chart.update(this.options);
        }
        if (this.circleTxt !== null) { this.circleTxt.destroy(); }
        this.circleTxt = this.chart.renderer.text(this.allStr).add();
        this.circleTxt.renderer.setStyle({ color: '#223345', fontSize: '1.4rem', textAlign: 'left' });
        const textBBox = this.circleTxt.getBBox();
        const x = this.chart.plotLeft + (this.chart.plotWidth * 0.25) - (textBBox.width * 0.5);
        const y = this.chart.plotTop + (this.chart.plotHeight * 0.6) - (textBBox.height * 0.5);
        this.circleTxt.attr({ x: x, y: y });
    }
}
