


























































import { Component, Vue, Prop } from 'vue-property-decorator';
import ProgramFilter from '@/modules/program-monitor2/components/program-filter.vue';
import BTablePag from '@/modules/program-monitor2/components/b-table-pag.vue';
import CChartWidget from '@/modules/program-monitor2/components/charts/c-chart-widget.vue';

@Component({
    name: 'c-prog-exec',
    components: {
        'c-prog-filter': ProgramFilter,
        'b-table-pag': BTablePag,
        'c-widg-chart': CChartWidget
    }
})
export default class ProgramExecution extends Vue {
    @Prop({
        required: true,
        default: { id: -1, name: '' }
    })
    private curProg!: any;

    @Prop({
        required: true,
        default: null
    })
    private directBaseData!: any | null;

    @Prop({
        required: true,
        default: null
    })
    private sphereData!: any | null;

    @Prop({
        required: true,
        default: false
    })
    private active!: boolean;

    private fields: any[] = [];

    private curDirection: any | null = null;
    private curSphere: any | null = null;
    private yearLst: any[] = [];
    private curYear: any | null = null;

    // ---------diagram
    private diagramData: any[] | null = null;
    private diagramColor = ['rgb(197, 221, 238)', 'rgb(134, 196, 238)', 'rgb(52, 150, 216)', '#c0504c'];
    private reflowTrig = 1; // значения 1 и -1 отслеживается изменение
    private chartVisible = false;
    // -----------

    private execBaseData: any | null = null; // данные по исполнению из базы
    private sortBy = 'event';
    private sortDesc = false;
    private curPage = 1;
    private perPage = 10;
    private pgRows = 0;

    private get directionLst(): any[] {
        if (this.directBaseData === null) { return []; }
        return this.getDictLangArr(this.directBaseData, 'direction', this.execBaseData);
    }

    private get sphereLst(): any[] {
        if (this.sphereData === null) { return []; }
        return this.getDictLangArr(this.sphereData, 'sphere', this.execData);
    }

    private getDictLangArr(data: any[], fieldName: string, execData: any[]) { // получение массива по справочникам с учётом языка
        const dictArr: any[] = [];
        for (const el of data) {
            const newObj: any = Object.assign({}, el);
            if (this.$i18n.locale === 'kk') { newObj.name = newObj.name_kz; } else { newObj.name = newObj['name_' + this.$i18n.locale]; }
            newObj.src = '';
            dictArr.push(newObj);
        }
        const res = [];
        if (execData !== null) {
            for (const el of execData) {
                if (el[fieldName] !== undefined && el[fieldName] !== null) {
                    for (let i = 0; i < dictArr.length; i++) {
                        const d = dictArr[i];
                        if (d.id === el[fieldName].id) {
                            res.push(d);
                            dictArr.splice(i, 1);
                            break;
                        }
                    }
                }
            }
            return res;
        }
        return dictArr;
    }

    private chgCurDir(val: null | any) {
        this.curSphere = null;
        this.curDirection = val;
    }
    private chgCurSph(val: null | any) {
        this.curSphere = val;
    }
    private chgCurY(val: null | any) {
        this.curYear = val;
    }

    private mounted() {
        // eslint-disable-next-line
        const that = this;
        this.loadExecData();
        this.$watch('curProg', this.loadExecData);
        this.$watch('active', function () {
            if (that.active) {
                that.reflowTrig = that.reflowTrig * (-1);
            }
        });
    }

    private get execData(): any[] {
        this.pgRows = 0;
        this.fields = [
            {
                key: 'event',
                label: 'Мероприятия',
                sortable: true
            },
            {
                key: 'deadline',
                label: 'Срок исполнения',
                sortable: true
            },
            {
                key: 'statusCode',
                label: 'Статус',
                sortable: true
            },
            {
                key: 'execInformation',
                label: 'Информация об исполнении',
                sortable: true
            },
            {
                key: 'respExecutors',
                label: 'Ответственные за исполнение',
                sortable: true
            }
        ];
        if (this.execBaseData === null) { return []; }
        this.diagramData = [{ name: 'Реализуется', y: 0 }, { name: 'Частично исполнено', y: 0 }, { name: 'Исполнено', y: 0 }, { name: 'Не исполнено', y: 0 }];
        const result: any[] = [];
        let flDeadLine = false;
        for (const el of this.execBaseData) {
            const obj = Object.assign({ statusTxt: '', statusCode: null }, el);
            if (this.checkFilter(obj)) {
                if (el.status !== null) {
                    if (this.$i18n.locale === 'kk') { obj.statusTxt = el.status.name_kz; } else { obj.statusTxt = el.status['name_' + this.$i18n.locale]; }
                    obj.statusCode = el.status.code;
                    switch (obj.statusCode) {
                        case '1':
                            this.diagramData[0].y++;
                            break;
                        case '4':
                            this.diagramData[1].y++;
                            break;
                        case '2':
                            this.diagramData[2].y++;
                            break;
                        case '3':
                            this.diagramData[3].y++;
                            break;
                        default:
                            break;
                    }
                }
                if (!flDeadLine && obj.deadline !== null && obj.deadline !== '') { flDeadLine = true; }
                result.push(obj);
            }
        }
        this.pgRows = result.length;
        if (!flDeadLine) {
            this.fields = [
                {
                    key: 'event',
                    label: 'Мероприятия',
                    sortable: true
                },
                {
                    key: 'statusCode',
                    label: 'Статус',
                    sortable: true
                },
                {
                    key: 'execInformation',
                    label: 'Информация об исполнении',
                    sortable: true
                },
                {
                    key: 'respExecutors',
                    label: 'Ответственные за исполнение',
                    sortable: true
                }
            ];
        }
        return result;
    }

    private checkFilter(obj: any) {
        if (this.curSphere !== null) {
            if (obj.sphere === null) { return false; }
            if (obj.sphere.id !== this.curSphere.id) { return false; }
        }
        if (this.curDirection !== null) {
            if (obj.direction === null) { return false; }
            if (obj.direction.id !== this.curDirection.id) { return false; }
        }

        if (this.curYear !== null) {
            if (obj.year !== this.curYear.val) { return false; }
        }
        return true;
    }

    private async loadExecData() {
        this.curPage = 1;
        this.execBaseData = await fetch('/api/forecast/events/execution/view?program=' + this.curProg.id)
            .then(response => response.json());
        this.getYearLst(this.execBaseData);
    }

    private getYearLst(data: any[]) { // получение списка годов из данных
        const result = [];
        this.yearLst = [];
        for (const el of data) {
            if (el.year !== null) { result.push(el.year); }
        }
        result.sort(function (d1: number, d2: number) { return (d1 - d2); });
        for (const el of result) {
            if (this.yearLst.length < 1 || this.yearLst[this.yearLst.length - 1].val !== el) {
                this.yearLst.push({ val: el });
            }
        }
        if (this.yearLst.length > 0) { this.curYear = this.yearLst[this.yearLst.length - 1]; } else { this.curYear = null; }
    }
}
