import { Component, Vue } from 'vue-property-decorator';
import CIndicCardTab, { IIndicCardData } from '@/modules/program-card/components/indic-card-tab.vue';
import CIndicMulti from '@/modules/program-card/components/indic-multiselect.vue';
import CProgramPasport from '@/modules/program-card/components/programm-pasport.vue';
import CProgramFinance from '@/modules/program-card/components/program-finance.vue';
import Multiselect from 'vue-multiselect';

@Component({
    components: {
        'multiselect': Multiselect,
        'c-indic-card-tab': CIndicCardTab,
        'c-indic-multi': CIndicMulti,
        'c-prog-pas': CProgramPasport,
        'c-prog-fin': CProgramFinance
    }
})
export default class ProgramCard extends Vue {
    private indicId: string | null = null;
    private progId: number | null = null;
    // private indicTabData: IIndicCardData[] = [];
    private curIndicTabIndx: number | null = null; // текущий индекс в таблице
    private tabIndex = 0;
    private tabIndex1 = 2;
    private yearsArr = []; // список годов
    private curYear = 0; // текущий год
    private monitorName: string | null = null; // имя текущего плана
    private directionLst: any[] = []; // список направлений
    private curDirection: any | null = null; // текущее направление
    private showModal = false; // модальное окно

    private statIndicLst: any[] = []; // список показателей из стата
    private curStatIndic: any[] = [null]; // текущие индикаторы из стата
    private calc = false; // вычисляемое ли
    private formula = ''; // формула

    private formulaData: any[] = []; // данные формулы
    private factData: any[] = []; // данные факт по curYear


    private mounted() {
        this.getParams(); // получение параметров url
        this.loadDirectionLst(); // загрузка списка направлений
        this.loadStatIndic(); // загрузка списка показателей со стата
    }

    private getParams() {
        const href = window.location.href;
        const strGET = href.substr(href.indexOf('?') + 1);
        this.indicId = strGET.substr(strGET.indexOf('=') + 1);
    }


    private get indicTabData(): IIndicCardData[] { // данные в таблице
        const resArr: IIndicCardData[] = [];
        if (this.curDirection === null) { return []; }
        for (let i = 0; i < this.curDirection.indicLst.length; i++) {
            const indic = this.curDirection.indicLst[i];
            resArr.push({ id: indic.id, indicName: indic.indicName, unitName: indic.unitName, plan: null });
            const obj = resArr[resArr.length - 1];
            // -----------
            if (this.factData.length > i) {
                obj.fact = this.factData[i];
            }
            // --проставить года в данных
            for (const item of indic.items) {
                if (item.date !== null && item.date !== undefined) {
                    item.year = new Date(item.date).getFullYear();
                }
                if (this.curYear === item.year) {
                    obj.plan = item.plan;
                }
            }
            // -----
        }
        return resArr;
    }

    // ------------------------- загрузка данных
    private async loadDirectionLst() {
        // eslint-disable-next-line
        const that = this;
        const monitorLst = [{ name: 'Программа развития регионов на 2016-2020 годы', directionLst: [], years: [2016, 2017, 2018, 2019, 2020] }, { name: 'Программа развития регионов на 2021-2025 годы', directionLst: [], years: [2021, 2022, 2023, 2024, 2025] }];

        const response = await fetch('/api/integration/mangystau-excel/target-indicators')
            .then(resp => resp.json());
        for (const el of response) {
            let fl = false;
            for (const monit of monitorLst) {
                if (el.program.toUpperCase() === monit.name.toUpperCase()) {
                    fl = true;
                    that.findDirection(monit.directionLst, el);
                }
            }
            if (!fl) {
                // eslint-disable-next-line
                console.log('el.program', el.program);
            }
        }
        this.findByIndicId(monitorLst);
        this.loadFormulaData();
    }

    private findByIndicId(monitorLst: any[]) {
        for (let i = 0; i < monitorLst.length; i++) {
            const monit = monitorLst[i];

            for (const direction of monit.directionLst) {
                for (const indic of direction.indicLst) {
                    if (indic.id === this.indicId) {
                        if (this.progId === null) { this.progId = i; }
                        // ----заполнить список событий directionLst
                        this.directionLst = monit.directionLst;
                        this.directionLst.push({});
                        this.directionLst.splice(this.directionLst.length - 1, 1);
                        this.curDirection = direction;
                        // --- список годов
                        this.yearsArr = monit.years;
                        this.curYear = this.yearsArr[0];
                        // -----------
                        this.monitorName = monit.name;
                        return;
                    }
                }
            }
        }
    }

    private findDirection(arr: any[], obj: any) {
        let fl = false;
        // eslint-disable-next-line
        for (let i = 0; i < arr.length; i++) {
            const el = arr[i];
            if (el.directName.toUpperCase() === obj.direction.toUpperCase()) {
                fl = true;
                el.indicLst.push({ id: obj.id, indicName: obj.indicator, unitName: obj.unit, items: obj.items });
                break;
            }
        }
        if (!fl) {
            arr.push({ directName: obj.direction, indicLst: [{ id: obj.id, indicName: obj.indicator, unitName: obj.unit, items: obj.items }] });
        }
    }
    // -------------------------

    private openModal(indx: number) {
        this.curIndicTabIndx = indx;
        this.showModal = true;
        this.curStatIndic = [];


        for (const el of this.formulaData[this.curIndicTabIndx].idStatIndic) {
            const obj = this.findStatIndic(el);
            this.curStatIndic.push(obj);
        }
        this.calc = this.formulaData[this.curIndicTabIndx].calc;
        this.formula = this.formulaData[this.curIndicTabIndx].formula;
        if (this.formulaData[this.curIndicTabIndx].idStatIndic.length === 0) { this.curStatIndic.push(null); }
    }

    private findStatIndic(direct: any) { // поиск стат индикаторов по ссылке (потому что id генерятся у Нуржана)
        for (const el of this.statIndicLst) {
            if (String(el.id) === String(direct.id)) {
                const obj = Object.assign({}, el);
                return obj;
            }
        }
        return null;
    }

    // загрузка списка показателей со стата
    private async loadStatIndic() {
        const result = await fetch('/api/integration/mangystau-excel/prt-indicators')
            .then(resp => resp.json());

        for (const el of result) {
            el.fullName = el.name + ' (' + el.period + ' : ' + el.unit + ')';
        }
        this.statIndicLst = result;
    }

    private updCurVal(data: any) {
        this.curStatIndic[data.indx] = data.val;
        this.curStatIndic.push({});
        this.curStatIndic.splice(this.curStatIndic.length - 1, 1);
    }

    private addIndic() {
        if (this.curStatIndic[this.curStatIndic.length - 1] !== null) {
            this.curStatIndic.push(null);
        }
    }

    private delIndic(indx: number) {
        this.curStatIndic.splice(indx, 1);
    }


    // --------------------------------------------------------
    private async loadFormulaData() { // загрузка formulaData
        this.formulaData = [];
        const result = await fetch('/api/program-monitor')
            .then(resp => resp.json());
        for (const indic of this.curDirection.indicLst) {
            this.formulaData.push(this.findFormulaData(indic.id, result));
        }
        this.getFactVal();
    }

    private findFormulaData(idIndic: string, data: any[]) { // поиск в даннах базы formulaData по idIndic
        for (const el of data) {
            if ((String(el.idIndic)) === String(idIndic)) { // тип числовой в сохранении
                return el;
            }
        }
        return { idIndic: idIndic, idStatIndic: [], formula: '', calc: false };
    }
    // -----------------------------------------------------

    private async getFactVal() {
        this.factData = [];
        if (this.curYear === null && this.formulaData.length === 0) { return; }
        const newArr: any = [];
        for (const formInd of this.formulaData) {
            newArr.push({ fact: null, idStatIndic: [] });
            const indx = newArr.length - 1;
            const ajx: any = [];
            for (const el of formInd.idStatIndic) {
                ajx.push({ Year: this.curYear, id: el.id });
            }
            // eslint-disable-next-line
            const objArr: any = await this.loadFactVal(ajx);
            for (const obj of objArr) {
                newArr[indx].idStatIndic.push({ id: obj.id, val: obj.value });
            }
            newArr[indx].fact = this.calcFormula(indx, newArr[indx].idStatIndic);
            this.factData.push(newArr[indx].fact);
        }
    }

    private calcFormula(indx: number, idStatIndic: any[]) { // расчет формулы (будет)
        if (this.formulaData[indx].calc) {
            let formula = this.formulaData[indx].formula + '';
            while (formula.indexOf(',') >= 0) {
                formula = formula.replace(',', '.');
            }
            let j = 0;
            const formArr = [];
            for (let i = 0; i < formula.length; i++) {
                if (formula[i] === '+' || formula[i] === '*' || formula[i] === '/' || formula[i] === '-' || i === formula.length - 1) {
                    if (i === formula.length - 1) {
                        formArr.push(formula.slice(j).trim());
                    } else {
                        const str = formula.substring(j, i).trim();
                        if (str.length > 0) { formArr.push(str); }
                        formArr.push(formula[i]);
                    }
                    j = i + 1;
                } else if (formula[i] === '{') {
                    const k = formula.indexOf('}', i);
                    const id = formula.substring(i + 1, k);
                    // eslint-disable-next-line @typescript-eslint/prefer-for-of
                    for (let p = 0; p < idStatIndic.length; p++) {
                        if (String(idStatIndic[p].id) === id) {
                            formArr.push(idStatIndic[p].val);
                            break;
                        }
                    }
                    i = k;
                    j = k + 1;
                }
            }

            let calcRes = null;
            for (let i = 0; i < formArr.length; i++) {
                const el = formArr[i];
                if (calcRes === null) {
                    calcRes = el;
                } else {
                    let val2 = 0;
                    if (el === '+' || el === '*' || el === '/' || el === '-') {
                        if (typeof formArr[i + 1] === 'string') {
                            val2 = parseFloat(formArr[i + 1]);
                        } else {
                            val2 = formArr[i + 1];
                        }
                        i++;
                    }
                    switch (el) {
                        case '+':
                            calcRes = calcRes + val2;
                            break;
                        case '-':
                            calcRes = calcRes - val2;
                            break;
                        case '/':
                            calcRes = calcRes / val2;
                            break;
                        case '*':
                            calcRes = calcRes * val2;
                            break;
                        default:
                            break;
                    }
                }
            }
            return calcRes;
        }
        if (idStatIndic !== null && idStatIndic.length > 0) { return idStatIndic[0].val; }
        return null;
    }

    private async loadFactVal(ajx: any) {
        const result = await fetch('/api/integration/stat/indicator-value',
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify(
                    ajx
                )
            }).then(resp => resp.json());
        // -------------------ЗАГЛУШКА!!!-------------------------
        if (this.curYear === 2020) {
            const arr = [
                { id: '1784017967', value: 7.4 },
                { id: '-1544813941', value: 102.8 },
                { id: '-1285325031', value: 109.7 },
                { id: '-509535043', value: 1963000 }
            ];
            for (const el of result) {
                if (el.value === null) {
                    // eslint-disable-next-line @typescript-eslint/prefer-for-of
                    for (let i = 0; i < arr.length; i++) {
                        if (String(el.id) === arr[i].id) {
                            el.value = arr[i].value;
                            break;
                        }
                    }
                }
            }
        }
        // ---------------------------------

        return result;
    }

    private clearFormulaData() { // удалить формулы
        this.curStatIndic = [];
        this.formula = '';
        this.calc = false;
        this.saveFormulaData();
    }

    // --------------------------------сохранение формул
    private async saveFormulaData() { // сохранение формул
        if (this.curIndicTabIndx !== null) {
            this.formulaData[this.curIndicTabIndx].idStatIndic = [];
            this.formulaData[this.curIndicTabIndx].calc = this.calc;
            this.formulaData[this.curIndicTabIndx].formula = this.formula;
            this.checkStatInFormula();
            for (const el of this.curStatIndic) {
                if (el !== null) {
                    this.formulaData[this.curIndicTabIndx].idStatIndic.push({ id: el.id, link: el.link });
                }
            }
        }

        const saveObj = [];
        if (this.curIndicTabIndx !== null) {
            saveObj.push(this.formulaData[this.curIndicTabIndx]);
        } else { return; }


        await fetch('/api/program-monitor',
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify(saveObj)
            });

        this.loadFormulaData();

        this.showModal = false;
    }

    private checkStatInFormula() { // проверить есть ли внутри формулы указанные idStatIndic
        let formula: any = {};
        if (this.curIndicTabIndx !== null) {
            formula = this.formulaData[this.curIndicTabIndx];
        }
        if (!formula.calc) {
            formula.formula = '';
            if (this.curStatIndic.length > 1) { this.curStatIndic.length = 1; }
            return;
        }

        let str = String(formula.formula);
        let indx = -1;
        for (let i = 0; i < this.curStatIndic.length; i++) {
            const el = this.curStatIndic[i];
            const elId = '{' + el.id + '}';
            let fl = false;
            indx = str.indexOf(String(elId));
            while (indx > 0) {
                str = str.replace(elId, '');
                indx = str.indexOf(String(elId));
                fl = true;
            }
            if (!fl) {
                this.curStatIndic.splice(i, 1);
                if (i > 0) { i--; }
            }
        }


        indx = str.indexOf('{', 0);
        while (indx >= 0) {
            const eIndx = str.indexOf('}', indx);
            const indic = str.substring(indx + 1, eIndx);
            const obj = this.findStatIndic({ id: indic });
            if (obj !== null) { this.curStatIndic.push(obj); }
            indx = str.indexOf('{', indx + 1);
        }
    }
    // ------------------------------
}