












































import { Component, Vue, Prop } from 'vue-property-decorator';
import Multiselect from 'vue-multiselect';

@Component({
    components: {
        'multiselect': Multiselect
    }
})
export default class CIndicMulti extends Vue {
    @Prop({
        required: true,
        default: null
    })
    private indx!: number | null;

    @Prop({
        required: true,
        default: null
    })
    private curData!: any | null;

    @Prop({
        required: true,
        default: null
    })
    private dataArr!: any[] | null;

    @Prop({
        required: true,
        default: { count: 1, calc: false }
    })
    private indicParams!: any;

    private get curVal(): any | null {
        console.log('curData', this.curData);
        if (this.curData == null) { return null; }
        return Object.assign({}, this.curData);
    }

    private get getDataArr(): any[] | null {
        console.log('dataArr', this.dataArr);
        if (this.dataArr == null) { return []; }
        return this.dataArr;
    }

    private set curVal(val: any | null) {
        const indx = this.indx;
        this.$emit('updCurVal', { val: val, indx: this.indx });
    }

    private delIndic() {
        this.$emit('delIndic', this.indx);
    }
}
