<template>
    <b-form inline class="select-menu">
        <div class="filter-container">
            <div class="filter-row">
                <div class="filter-block">
                    <b-form-group label="Год ">
                        <b-form-select
                            v-model="years"
                            :options="listYears"
                            value-field="year"
                            @change="emitToParent"
                        >
                        </b-form-select>
                    </b-form-group>
                </div>
                <div class="filter-block">
                    <b-form-group label="Месяц    ">
                        <b-form-select
                            v-model="month"
                            :options="listMonth"
                            value-field="month"
                            @change="emitToParent"
                        >
                        </b-form-select>
                    </b-form-group>
                </div>
                <div class="filter-block">
                    <b-form-group label="Область/район  ">
                        <b-form-select
                            v-model="region"
                            :options="listReg"
                            value-field="code"
                            @change="emitToParent"
                        >
                        </b-form-select>
                    </b-form-group>
                </div>
                <div class="filter-block" >
                    <b-form-group label="">{{resources.sob.text}}
                        <b-form-checkbox v-model="resources.sob.switch" @change="onChange" switch style="margin-left: 20px"></b-form-checkbox>
                    </b-form-group>
                    <b-form-group label="">{{resources.all.text}}
                        <b-form-checkbox v-model="resources.all.switch" @change="onChange" switch style="margin-left: 20px"></b-form-checkbox>
                    </b-form-group>
                </div>
                <b-dropdown variant="link" right toggle-class="text-decoration-none" no-caret>
                    <template #button-content>
                        <div class="btn-download">
                            <div>
                                        <span class="left">
                                            <i class="icon icon-info"></i>
                                        </span>
                                <i class="icon icon-keyboard icon-rotate-90"></i>
                            </div>
                        </div>
                    </template>
                    <b-dropdown-item>
                        <div>
                            <div><span>Режим «Сводные данные»</span></div>
                            <div>
                                <br>
                                Сводные данные исполнения бюджета
                            </div>
                        </div>
                    </b-dropdown-item>
                </b-dropdown>
                <b-dropdown variant="link" right toggle-class="text-decoration-none" no-caret>
                    <template #button-content>
                        <div class="btn-download">
                            <div>
                            <span class="left">
                                <i class="icon icon-download"></i> Скачать
                            </span>
                                <i class="icon icon-keyboard icon-rotate-90"></i>
                            </div>
                        </div>
                    </template>
                </b-dropdown>

            </div>
        </div>
    </b-form>
</template>

<script>
import '../BudgetView.scss';
import { Ax } from '@/utils';

export default {
    name: 'FilterRow',
    props: {
        selectData: {}
    },
    data() {
        return {
            years: [],
            month: [],
            region: {},
            listReg: [],
            listYears: [],
            listMonth: [],
            curRes: { text: 'Собственные средства', switch: true, value: 1 },
            resources: {
                sob: { text: 'Собственные средства', switch: true, value: 1 },
                all: { text: 'Все поступления', switch: false, value: 2 }
            },
            curFileLst: null
        };
    },

    methods: {
        emitToParent() {
            console.log('emitToParent');
            this.$emit('filter-change', this.selectData);
        },
        // справочник регионов
        loadRegions() {
            const that = this;
            Ax(
                {
                    url: '/api-py/get-regions-by-obl/' + '43', //  Тело файла
                    method: 'GET'
                },
                (data) => {
                    that.listReg = JSON.parse(JSON.stringify(data));
                    const regions = data;
                    this.regions = data;
                    this.region = regions[0].code;
                },
                (error) => {
                    that.error = error;
                }
            );
        },
        // список загруженных лет
        loadYears() {
            const that = this;
            Ax(
                {
                    url: '/api-py/get-years-by-obl/' + '43', //  Тело файла
                    method: 'GET'
                },
                (data) => {
                    that.listYears = JSON.parse(JSON.stringify(data));
                    this.years = that.listYears[0].year;
                    this.loadMonth(this.years);
                },
                (error) => {
                    that.error = error;
                }
            );
        },
        // список загруженных месяцев по выбранному году
        loadMonth(year) {
            const that = this;
            Ax(
                {
                    url: '/api-py/get-month-by-obl/' + year, //  Тело файла
                    method: 'GET'
                },
                (data) => {
                    that.listMonth = JSON.parse(JSON.stringify(data));
                    this.month = that.listMonth[0].month;
                },
                (error) => {
                    that.error = error;
                }
            );
        },
        onChange() {
            /* this.$watch('resources.sob.switch', (val) => {
                if (val) {
                    this.resources.all.switch = false;
                    this.curRes = this.resources.all;
                    //this.loadData()
                }
            });
            this.$watch('resources.all.switch', (val) => {
                if (val) {
                    this.resources.sob.switch = false;
                    this.curRes = this.resources.sob;
                    //this.loadData()
                }
            });
*/
        }
    },
    mounted() {
        this.loadYears();
        this.loadRegions();
    },
    watch: {
        'resources.sob.switch': function (val) {
            this.resources.sob.switch = val;
            this.resources.all.switch = !val;
            this.curRes = this.resources.sob;
        },
        'resources.all.switch': function (val) {
            this.resources.all.switch = val;
            this.resources.sob.switch = !val;
            this.curRes = this.resources.all;
        }
    }
};
</script>

<style scoped>
.select-menu {
    margin: 5px;
    font-size: 1em;
    background-color: #E3EEF6;
}
.select-label {
    margin-left: 5px;
    margin-right: 5px;
}
</style>