import { Vue, Component } from 'vue-property-decorator';
import CPanel from './c-panel';
import CIndicator from './c-indicator';

@Component({
    components: {
        'c-panel': CPanel,
        'c-indicator': CIndicator
    }
})

export default class Program extends Vue {
    public sectionId = 0;
    private monitorLst = [
        { name: 'Программа развития регионов на 2016-2020 годы', directionLst: [] },
        { name: 'Программа развития регионов на 2021-2025 годы', directionLst: [] }
    ];

    private section: any = '';

    private years = ['2016 год', '2017 год', '2018 год', '2019 год', '2020 год'];
    private selected_year = '2020 год';

    private monitors = ['Программа развития регионов на 2016-2020 годы', 'Программа развития регионов на 2021-2025 годы'];
    private selected_monitor = 'Программа развития регионов на 2016-2020 годы';

    public onSelectSection(data: any) {
        this.sectionId = data.value;
        const lst: any = this.monitorLst[0];
        if (lst.directionLst.length >= this.sectionId) {
            this.section = lst.directionLst[this.sectionId];
            this.indicatorData = this.section.indicLst;
        }
    }


    private mounted() {
        this.loadDirectionLst();
    }

    private async loadDirectionLst() {
        const that = this;

        const resp = await fetch('/api/integration/mangystau-excel/target-indicators')
            .then(response => response.json());
        for (const el of resp) {
            let fl = false;
            for (const monit of this.monitorLst) {
                if (el.program.toUpperCase() === monit.name.toUpperCase()) {
                    fl = true;
                    that.findDirection(monit.directionLst, el);
                }
            }
            if (!fl) {
                this.monitorLst.push({ name: el.program, directionLst: [] });
                that.findDirection(that.monitorLst[that.monitorLst.length - 1].directionLst, el);
            }
        }
    }

    private findDirection(arr: any[], obj: any) {
        let fl = false;
        for (let i = 0; i < arr.length; i++) {
            const el = arr[i];
            if (el.directName.toUpperCase() === obj.direction.toUpperCase()) {
                fl = true;
                el.indicLst.push({ id: obj.id, indicName: obj.indicator, unitName: obj.unit, items: obj.items });
                break;
            }
        }
        if (!fl) {
            arr.push({ directName: obj.direction, indicLst: [{ id: obj.id, indicName: obj.indicator, unitName: obj.unit, items: obj.items }] });
        }
    }

    private indicatorData = [];
}