<template>
    <b-form>
        <div class="card-loader-body">
            <b-row>
                <b-col cols="12" lg="6">
                    <b-form-group id="inpgroup" label="Наименование:" label-for="inp-11">
                        <b-form-input
                            id="inp-11"
                            @keydown.stop.prevent="keyEvent($event)"
                            @keyup.stop.prevent="keyEvent($event)"
                            @keypress.stop.prevent="keyEvent($event)"
                            v-model="propData.name"
                            placeholder=""
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="12" lg="6">
                    <b-form-group id="inpgroup-2" label="Вид бюджета:" label-for="inp-21">
                        <b-form-input
                            id="inp-21"
                            @keydown.stop.prevent="keyEvent($event)"
                            @keyup.stop.prevent="keyEvent($event)"
                            @keypress.stop.prevent="keyEvent($event)"
                            v-model="propData.vid"
                            placeholder=""
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="12" lg="3">
                    <b-form-group id="inpgroup-3" label="Период:" label-for="inp-31">
                        <b-form-input
                            id="inp-31"
                            @keydown.stop.prevent="keyEvent($event)"
                            @keyup.stop.prevent="keyEvent($event)"
                            @keypress.stop.prevent="keyEvent($event)"
                            v-model="propData.period"
                            placeholder=""
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="12" lg="3">
                    <b-form-group id="inpgroup-4" label="Дата:" label-for="inp-41">
                        <b-form-input
                            id="inp-41"
                            @keydown.stop.prevent="keyEvent($event)"
                            @keyup.stop.prevent="keyEvent($event)"
                            @keypress.stop.prevent="keyEvent($event)"
                            v-model="propData.data"
                            placeholder=""
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="12" lg="3">
                    <b-form-group id="inpgroup-5" label="Единица измерения:" label-for="inp-51">
                        <b-form-input
                            id="inp-51"
                            @keydown.stop.prevent="keyEvent($event)"
                            @keyup.stop.prevent="keyEvent($event)"
                            @keypress.stop.prevent="keyEvent($event)"
                            v-model="propData.mesuare"
                            placeholder=""
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="12" lg="3">
                    <b-form-group id="inpgroup-6" label="Регион (область, город, район):" label-for="inp-61">
                        <b-form-input
                            id="inp-6"
                            @keydown.stop.prevent="keyEvent($event)"
                            @keyup.stop.prevent="keyEvent($event)"
                            @keypress.stop.prevent="keyEvent($event)"
                            v-model="propData.region"
                            placeholder=""
                        ></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
        </div>
        <div class="card-loader-bottom">
            <b-button variant="success" @click="CheckHash()">Загрузить</b-button>
            <b-button variant="default" @click="onClear()">Отмена</b-button>
        </div>
        <b-tabs class="tabs-in-tabs">
            <b-tab title="Загружаемые данные">
                <div class="table-container">
                    <b-table id="my-table-body" sticky-header bordered head-variant="light" :items="fileBody"
                             :fields="fields"
                             :table-variant="light">
                        <template #cell(code)="row" class="tabBody">
                            <div align="center" class="code_text">
                                {{ row.item.code }}
                            </div>
                        </template>
                        <template #cell(name)="row">
                            <div align="left" class="code_text">
                                {{ row.item.name }}
                            </div>
                        </template>
                        <template #cell(region)="row">
                            <div align="center" class="code_text">
                                {{ row.item.region }}
                            </div>
                        </template>
                        <template #cell(region_name)="row">
                            <div align="left" class="code_text">
                                {{ row.item.region_name }}
                            </div>
                        </template>
                        <template #cell(year)="row">
                            <div align="center" class="code_text">
                                {{ row.item.year }}
                            </div>
                        </template>
                        <template #cell(month)="row">
                            <div align="center" class="code_text">
                                {{ row.item.month }}
                            </div>
                        </template>
                        <template #cell(plan)="row">
                            <div align="right" class="code_text">
                                {{ $n(row.item.sum) }}
                            </div>
                        </template>
                    </b-table>
                </div>
            </b-tab>
            <b-tab title="Журнал загрузки">
                <journal-table></journal-table>
            </b-tab>
        </b-tabs>
    </b-form>
</template>

<script>
import '../BudgetView.scss'
import {Ax} from "@/utils";
import numFormat from 'vue-filter-number-format';
import JournalTable from "@/modules/budget/components/Journal";
import store from "@/services/store";

export default {
    name: 'ExpLoader',
    components: { JournalTable },
    props: {
        propData: {},
        fileBody: {},
        fileName: {},
        file: {}
    },
    data() {
        return {
            light: 'light',
            fields: [
                {key: 'year', label: 'Год', sortable: false},
                {key: 'month', label: 'Месяц', sortable: false},
                {key: 'code', label: 'Код бюджетной классификации', sortable: false},
                {key: 'name', label: 'Наименование', sortable: false},
                {key: 'sum', label: 'Факт', sortable: false}
            ]
        };
    },
    methods: {
        userLogin() {
            return store.state.user.login;
        },
        async CheckHash() {
            const formData = new FormData();
            formData.append('file', this.file, this.fileName);
            const response = await fetch('/api-py/check-hash', {
                method: 'POST',
                body: formData
            });
            const result = await response.json();
            console.log(result);
            if (result === true) {
                this.handleOk();
            } else {
                this.onFactLoad(formData);
            }
        },
        handleOk() {
            //const formData = { file: this.fileName };
            const formData = new FormData();
            formData.append('file', this.file, this.fileName);
            this.$bvModal.msgBoxConfirm('Сохранение приведет к изменению ранее принятых данных', {
                title: 'Подтвердите',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Сохранить',
                cancelTitle: 'Отменить',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    console.log('Save', value);
                    if (value) {
                        this.onFactLoad(formData);
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        },
        onFactLoad(formData) {
            const user = this.userLogin();
            this.$emit('save-load', true);
            Ax(
                {
                    url: '/api-py/save-bfact/'+ user,
                    method: 'POST',
                    data: formData
                }
                // (data) => {
                //     console.log('File uploaded successfully');
                //     this.makeToast('success', 'Файл сохранен !', 'Результат');
                //     this.$emit('save-load', false);
                // },
                // (error) => {
                //     this.error = error;
                //     console.log('File not loaded');
                //     this.makeToast('danger', 'Ошибка сохранения !', 'Результат');
                //     this.$emit('save-load', false);
                // }
            );
            this.makeToast('success', 'Загрузка файла начата! Результат в журнале Загрузки', 'Результат');
            this.$emit('save-load', false);
        },
        // eslint-disable-next-line no-shadow
        onClear(event) {
            this.$emit('form-clear', []);
            this.propData.name = '';
            this.propData.vid = '';
            this.propData.period = '';
            this.propData.data = '';
            this.propData.mesuare = '';
            this.propData.region = '';
        },
        makeToast(variant = null, tostbody, title) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                autoHideDelay: 4000,
                solid: true
            })
        },
        // eslint-disable-next-line no-shadow,@typescript-eslint/no-empty-function
        keyEvent(event) {
        }
    }
};
</script>

<style scoped>
    .code_text {
        font-size: 0.8em;
    }

    .tabBody {
        font-weight: bold;
        font-style: italic;
        font-size: 1.8em;
        background-color: white;
    }
</style>