
































import { Component, Vue, Prop } from 'vue-property-decorator';


@Component({
    name: 'c-prog-pas'
})
export default class CProgramPasport extends Vue {
    @Prop({
        required: true,
        default: 0
    })
    private progId!: number | null;

    private get pasData(): any[] {
        if (this.progId === 0) { // 'Программа развития регионов на 2016-2020 годы'
            return [
                { col1: 'Наименование Программы', col2: ['Программа развития территории Мангистауской области на 2016-2020 годы '] },
                { col1: 'Разработчик Программы ', col2: ['Министерство национальной экономики Республики Казахстан'] },
                { col1: 'Направления программы ', col2: ['Экономика', 'Социальная сфера', 'Инфраструктура'] },
                {
                    col1: 'Цели программы',
                    col2: ['Цели программы',
                        'Формирование конкурентоспособных специализаций региона, обеспечивающих устойчивый рост экономики;',
                        'Развитие обрабатывающих отраслей и улучшение инвестиционного климата',
                        'Создание условий для увеличения объемов производства продукции сельского хозяйства',
                        'Активное развитие малого и среднего бизнеса современных торговых форматов;',
                        'Улучшение качества и доступности образования, повышение эффективности молодежной политики',
                        'Укрепление здоровья населения области',
                        'Повышение занятости, эффективности социальной помощи и оказания социальных услуг',
                        'Повышение общедоступности услуг сферы культуры для населения',
                        'Развитие массового и детско-юношеского спорта',
                        'Развитие туристического кластера и инфраструктуры туризма',
                        'Обеспечение правопорядка и профилактика уличной преступности и бытового насилия',
                        'Повышение эффективности системы предотвращения чрезвычайных ситуаций и ликвидации их последствий',
                        'Содействие развитию строительной отрасли, в том числе жилищного строительства и приобретение жилья компаний-застройщиков;',
                        'Улучшение обеспечения потребителей коммунальными услугами',
                        'Повышение транспортной доступности и транзитного потенциала региона',
                        'Сохранение природных ресурсов и улучшение окружающей среды']
                },
                { col1: 'Сроки реализации', col2: ['2016-2020'] }

            ]
        }
        return [];
    }
}


