<template>
    <b-container fluid="true">
        <b-navbar style="background-color: #2196f3">
            <div class="my-nav-brand"> </div>
            <b-navbar-brand href="#" >
                <img src="/img/component/budget.png" class="d-inline-block align-top">
                БЮДЖЕТ
            </b-navbar-brand>
        </b-navbar>
        <b-row>
            <b-col cols="2" class="budget-nav">
                <div >
                    <div class="section" @click="active=1" :class="{'active': (active === 1)}">
                        <div>
                            <img src="/img/panel/vector-0.png"/>
                        </div>
                        <span>{{ $t ('modules.budget.summary_data') }}</span>
                    </div>
                    <div class="section" @click="active=2" :class="{'active': (active === 2)}">
                        <div>
                            <img src="/img/component/budget.png"/>
                        </div>
                        <span>{{ $t ('modules.budget.data_on_receipts') }}</span>
                    </div>
                    <div class="section" @click="active=3" :class="{'active': (active === 3)}">
                        <div>
                            <img src="/img/component/budget.png"/>
                        </div>
                        <span>{{ $t ('modules.budget.cost_data') }}</span>
                    </div>
                    <div class="section" @click="active=4" :class="{'active': (active === 4)}">
                        <div>
                            <img src="/img/component/budget.png"/>
                        </div>
                        <span>{{ $t ('modules.budget.large_payers') }}</span>
                    </div>
                </div>
            </b-col>

            <b-col>
                <summary-data v-if="active===1"></summary-data>
                <receipts-data v-if="active===2"></receipts-data>
                <cost-data v-if="active===3"></cost-data>
                <large-payers v-if="active===4"></large-payers>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import SummaryData from '@/modules/budget/components/SummaryData';
import ReceiptsData from '@/modules/budget/components/ReceiptsData';
import CostData from '@/modules/budget/components/CostData';
import LargePayers from '@/modules/budget/components/LargePayers';
import './BudgetView.scss';

export default {
    name: 'BudgetView',
    components: { SummaryData, ReceiptsData, CostData, LargePayers },
    data() {
        return {
            active: 1
        };
    }
};
</script>

<style scoped>

.my-nav-brand {
    margin-left: 200px;
}

</style>