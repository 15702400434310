import { render, staticRenderFns } from "./programm-pasport.vue?vue&type=template&id=23375f35&scoped=true&"
import script from "./programm-pasport.vue?vue&type=script&lang=ts&"
export * from "./programm-pasport.vue?vue&type=script&lang=ts&"
import style0 from "./programm-pasport.vue?vue&type=style&index=0&id=23375f35&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23375f35",
  null
  
)

export default component.exports