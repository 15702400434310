















































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

@Component({
    name: 'c-prog-method'
})
export default class CProgMethod extends Vue {
}
