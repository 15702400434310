<template>
    <b-container fluid="true">
        <h5>Перевод будет сохранен в проекте в файлы </h5>
        <h3>front/public/i18n/</h3>
        <b-row style="padding: 20px">
            <b-form-group label-size="sm" label-cols="2" label="В разделе:"
                          label-for="key" class="ml-3">
                <b-input-group>
                    <b-form-input disabled id="sl1" value="messages." class="form-control mt-1"></b-form-input>
                    <b-form-input id="key" v-model="mainKey" class="form-control mt-1"></b-form-input>
                </b-input-group>
            </b-form-group>
            <b-button size="sm" @click="create" class="h-50 ml-5" variant="primary">Создать перевод</b-button>
        </b-row>
        <b-table :fields="tablefields"
                 :items="filteredItems"
                 striped bordered
                 @row-dblclicked="edit">
            <template #top-row="data">
                <td v-for="field in data.fields" :key="field.key">
                    <b-form-input v-if="field.filter_context" v-model="contextVal[field.key]">
                    </b-form-input>
                </td>
            </template>
            <template #empty="scope">
                <h4>Ничего не найдено</h4>
            </template>
            <template #cell(actions)="row">
                <b-button size="sm" @click="edit(row.item, row.index, $event.target)" class="mr-1">
                    Просмотр
                </b-button>
            </template>
        </b-table>
        <b-modal id="edit_form" title="Редактор" size="xl"
                 @ok="handleOk()"
                 okTitle="Сохранить"
                 cancelTitle="Отмена"
        >
            <b-form>
                <b-alert :show="showAlert">{{ alertMessage }}</b-alert>
                <b-form-group label-size="sm" label-cols="4" label="На русском (вводить сюда)"
                              label-for="ru">
                    <b-input-group>
                        <b-form-input debounce="500" v-model="selectedItem.ru" required placeholder="Введите текст"
                                      class="mt-1"></b-form-input>
                        <b-input-group-append>
                            <b-button :disabled="selectedItem.ru==''" variant="info"
                                      @click="getTranslate(selectedItem.ru)" class="ml-1 h-100">Перевести
                            </b-button>
                        </b-input-group-append>
                        <pre v-if="false">{{ item }}</pre>
                    </b-input-group>
                </b-form-group>
                <b-form-group label-size="sm" label-cols="4" label="На казахском"
                              label-for="kk">
                    <!--                    <b-form-input  id="kk" v-model="selectedItem.kk"></b-form-input>-->
                    <b-input-group>
                        <b-form-input id="kk" v-model="selectedItem.kk"></b-form-input>
                        <!--                        <b-button @click="selectedItem.kk=selectedItem.kk_temp">-</b-button>-->
                        <!--                        <b-form-input id="kkv" v-model="selectedItem.kk_temp"></b-form-input>-->
                    </b-input-group>
                </b-form-group>
                <b-form-group label-size="sm" label-cols="4" label="На английском"
                              label-for="en">

                    <b-input-group>
                        <b-form-input id="en" v-model="selectedItem.en"></b-form-input>
                        <!--                        <b-button @click="selectedItem.en=selectedItem.en_temp">-</b-button>-->
                        <!--                        <b-form-input id="env" v-model="selectedItem.en_temp"></b-form-input>-->
                    </b-input-group>
                </b-form-group>

                <b-form-group label-size="sm" label-cols="4" label="Путь ключ, для вставки в код"
                              label-for="key">
                    <b-input-group>
                        <!--                        <b-form-input disabled id="sl1" :value="'messages.'+selectedItem.key" class="form-control mt-1"></b-form-input>-->
                        <b-form-input id="key" v-model="selectedItem.key" class="form-control mt-1"></b-form-input>
                        <!--                        <b-button @click="selectedItem.key=selectedItem.key_temp">-</b-button>-->
                        <!--                        <b-form-input id=".key_temp" v-model="selectedItem.key_temp"></b-form-input>-->
                    </b-input-group>
                </b-form-group>

            </b-form>
        </b-modal>
    </b-container>
</template>

<script>

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import {Ax} from '@/utils';

export default {
    name: 'I18nEditor',
    data() {
        return {
            mainKey: 'modules.',
            stored_data: [],
            translate: {},
            contextVal: [],
            selectVal: [],
            dateSearchEnable: false,
            alertMessage: '',
            showAlert: false,
            tablefields: [
                {
                    key: 'key',
                    label: 'Ключ',
                    sortable: true,
                    filter_context: true
                },
                {
                    key: 'ru',
                    label: 'Русский',
                    sortable: true,
                    filter_context: true
                },
                {
                    key: 'kk',
                    label: 'Казахский',
                    sortable: true,
                },
                {
                    key: 'en',
                    label: 'Английский',
                    sortable: true,
                },
                {
                    key: 'actions',
                    label: 'Действия'
                }
            ],
            selectedItem_ru: '',
            isEditing: false,
            selectedItem: {
                key: 'modules.',
                ru: '',
                kk: '',
                kkv: '',
                en: '',
                env: ''
            }
        }
    },
    methods: {
        loadMessages() {
            Ax(
                {
                    url: 'http://localhost:5050/api-py/get-i18n-texts',
                    method: 'GET'
                },
                (data) => {
                    this.stored_data = JSON.parse(JSON.stringify(data));
                },
                (error) => {
                    this.error = error;
                }
            );
        },
        edit(item) {
            this.isEditing = true;
            this.selectedItem = item
            this.$bvModal.show('edit_form')
        },
        create() {
            this.isEditing = false;
            this.showAlert = false;
            this.selectedIndex = -1
            this.selectedItem = {
                key: 'modules.',
                ru: '',
                kk: '',
                kk_temp: '',
                en: '',
                en_temp: ''
            }
            if (!this.mainKey.endsWith('.')) {
                this.mainKey = this.mainKey + '.'
            }
            this.selectedItem.key = this.mainKey
            this.$bvModal.show('edit_form')
        },
        getTranslate(text) {
            this.$set(this.contextVal, 'ru', text)
            if (this.filteredItems.length > 0) {
                this.alertMessage = 'Есть похожие варианты: ' + this.filteredItems.length;
                this.showAlert = true;
            }
            Ax(
                {
                    url: 'http://localhost:5050/api-py/get-i18n-translate/' + text,
                    method: 'GET'
                },
                (data) => {
                    this.translate = JSON.parse(JSON.stringify(data));
                    this.$set(this.selectedItem, 'kk', this.translate.kk)
                    this.$set(this.selectedItem, 'en', this.translate.en)
                    if (!this.isEditing) {
                        this.$set(this.selectedItem, 'key', 'modules.' + this.translate.en.toLowerCase().replace(/ /g, '_').replace('.', '_'))
                    }
                },
                (error) => {
                    this.error = error;
                }
            );
        },
        handleOk() {
            this.$bvModal.msgBoxConfirm('Сохранить данный перевод?', {
                title: 'Подтвердите',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Да',
                cancelTitle: 'Нет',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    console.log('Save', value)
                    if (value) {
                        this.save()
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },
        save() {
            const saveData = {
                key: this.selectedItem.key,
                ru: this.selectedItem.ru,
                kk: this.selectedItem.kk,
                en: this.selectedItem.en
            }
            Ax(
                {
                    url: 'http://localhost:5050/api-py/save-i18n',
                    method: 'POST',
                    data: saveData
                },
                (data) => {
                    this.translate = JSON.parse(JSON.stringify(data));
                },
                (error) => {
                    this.error = error;
                }
            );
            console.log('Save to server')
        }
    },
    computed: {
        filteredItems() {
            return this.stored_data.filter(storedData => {
                for (let keyIndex = 0; keyIndex < this.tablefields.map(fItem => fItem.key).length; keyIndex++) {
                    // for (let keyIndex in this.tablefields.map(fItem => fItem.key)) {
                    if (this.tablefields.hasOwnProperty(keyIndex)) {
                        const key = this.tablefields[keyIndex].key
                        const filterOptions = this.tablefields[keyIndex].filter_options
                        if (Object.getOwnPropertyNames(this.selectVal).length === 0) break
                        if (key && filterOptions && this.selectVal[key] !== undefined) {
                            if (this.selectVal[key] > 0) {
                                const dictItem = this.dict[filterOptions][(this.selectVal[key] - 1)]
                                if (storedData[key].toLowerCase() !== dictItem.text.toLowerCase()) {
                                    return false
                                }
                            }
                        }
                        if ((this.contextVal[key] !== undefined) && (storedData[key] !== undefined)) {
                            if (this.contextVal[key].length > 0) {
                                const search = this.contextVal[key].toLowerCase()
                                const sourceText = storedData[key].toLowerCase()
                                return sourceText.includes(search)
                            }
                        }
                        if (this.dateSearchEnable) {
                            const bdate = new Date(this.search.dateregbeg)
                            const edate = new Date(this.search.dateregend)
                            if ((storedData['pub_date_format'] >= bdate) && (storedData['pub_date_format'] <= edate)) {
                                return true
                            } else {
                                return false
                            }
                        }
                    }
                }
                return true
            })
        },
        readyToSave() {
            const res = (this.selectedItem.key.length > 0) & (this.selectedItem.ru.length > 0)
                & (this.selectedItem.kk.length > 0) & (this.selectedItem.en.length > 0)
            return res != 0
        }
    },
    mounted() {
        this.loadMessages()
    }
}
</script>

<style scoped>
.form-control {
    border: 1px solid #ced4da;
    border-radius: 3px;
}
</style>