<template>
    <b-container fluid="true" class="sub-container">
        <h3 class="budget-header">{{ $t ('modules.budget.plan_execution_summary') }}</h3>

        <filter-row :select-data="filter" v-on:filter-change="filterChange"></filter-row>
        <b-row>
            <b-col cols="12" sm="12" lg="4" xl="4" class="container-col">
                <group-container :header="$t ('modules.budget.percentage_of_execution')" >
                    <doughnut-chart :options="options" :chart-data="doughnutData" class="my-chart"></doughnut-chart>
                </group-container>

            </b-col>
            <b-col cols="12" sm="12" lg="8" xl="8">
                <group-container :header="$t ('modules.budget.plan_fact')" img-src="/img/component/budget.png">
                    <horizontal-bar :options="optionsBar" :chart-data="barData" class="my-bar-chart"></horizontal-bar>
                </group-container>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" lg="12" xl="12">
                <group-container>
                    <b-table :fields="tableCols" :items="budgetPlan" no-border-collapse>
                        <template #head(region)="row">
                            <div class="text-center">{{ $t ( row.label) }}</div>
                        </template>
                        <template #cell(region)="row">
                            <div class="text-left">
                                {{ row.item.region }}
                            </div>
                        </template>
                        <template #head(plan)="row">
                            <div class="text-center">{{ $t (row.label) }}</div>
                        </template>
                        <template #cell(plan)="row">
                            <div class="text-right">
                                {{ numberWithCommas(row.item.plan) }}
                            </div>
                        </template>
                        <template #head(fact)="row">
                            <div class="text-center">{{ $t (row.label) }}</div>
                        </template>
                        <template #cell(fact)="row">
                            <div class="text-right">
                                {{ numberWithCommas(row.item.fact) }}
                            </div>
                        </template>
                        <template #head(percent)="row">
                            <div class="text-center">{{ $t( row.label) }}</div>
                        </template>
                        <template #cell(percent)="row">
                            <div class="text-right">
                                {{ numberWithCommas(row.item.percent) }}
                            </div>
                        </template>
                        <template slot="custom-foot">
                            <b-td variant="primary" class="text-left">{{ $t ('modules.budget.total') }}</b-td>
                            <b-td variant="primary" class="text-right">{{ $n(totalPlan) }}</b-td>
                            <b-td variant="primary" class="text-right">{{ $n(totalFact) }}</b-td>
                            <b-td variant="primary" class="text-right">{{ $n(numberWithCommas(totalPercent)) }}</b-td>
                        </template>
                    </b-table>
                </group-container>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import FilterRow from '@/modules/budget/components/FilterRow';
import '../BudgetView.scss';
import GroupContainer from '@/modules/budget/components/GroupContainer';
import DoughnutChart from '@/components/charts/DoughnutChart';
import HorizontalBar from '@/components/charts/HorizontalBar';

export default {
    name: 'SummaryData',
    components: { FilterRow, GroupContainer, DoughnutChart, HorizontalBar },
    data() {
        return {
            height: 20,
            filter: {
                year: 2020,
                region: {},
                date: new Date()
            },
            tableCols: [
                {
                    key: 'region',
                    label: 'modules.budget.district',
                    sortable: true
                },
                {
                    key: 'plan',
                    label: 'modules.budget.plan,_thousand_tenge',
                    sortable: true,
                    variant: 'warning'
                },
                {
                    key: 'fact',
                    label: 'modules.budget.fact,_thousand_tenge',
                    sortable: true,
                    variant: 'info'
                },
                {
                    key: 'percent',
                    label: 'modules.budget.execution_percent',
                    sortable: true

                }
            ],
            options: {
                responsive: true,
                cutoutPercentage: 60,
                legend: {
                    display: false
                },
                title: {
                    display: true,
                    position: 'top',
                    text: '85.5%',
                    fontSize: '35',
                    padding: -170
                }
            },
            optionsBar: {
                type: 'horizontalBar',
                responsive: true,
                legend: {
                    display: false

                },
                scaleLabel: {
                    display: false,
                    align: 'start',
                    position: 'left',
                    fullWidth: true,
                    fontSize: 30
                },
                scales: {
                    xAxes: [{
                        gridLines: {
                            display: true,
                            lineWidth: 1,
                            borderDashOffset: 50
                        }
                    }]
                },
                tooltips: {
                    callbacks: {
                        label: function (tooltipItem, data) {
                            let label = data.datasets[tooltipItem.datasetIndex].label || '';

                            if (label) {
                                label += ': ';
                            }
                            label += Math.round(tooltipItem.yLabel * 100) / 100;
                            return label;
                        }
                    }
                },
                title: {}
            },
            doughnutData: {
                datasets: [{
                    data: [10, 20, 30, 12, 15, 7],
                    backgroundColor: [
                        '#EF5350', '#EC407A', '#AB47BC',
                        '#42A5F5', '#9CCC65', '#FF7043',
                        '#8D6E63', '#FFEE58', '#26A69A'
                    ]
                }
                ],
                labels: [
                    'Red',
                    'Yellow',
                    'Blue'
                ]
            },
            barData: {
                labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
                datasets: [
                    {
                        categoryPercentage: 0.4,
                        barPercentage: 0.6,
                        barThickness: 8,
                        maxBarThickness: 20,
                        minBarLength: 10,
                        label: 'GitHub Commits',
                        backgroundColor: '#f87979',
                        data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11]
                    },
                    {
                        categoryPercentage: 0.4,
                        barPercentage: 0.6,
                        barThickness: 8,
                        maxBarThickness: 20,
                        minBarLength: 10,
                        label: 'GitHub Commits',
                        backgroundColor: '#FFEE58',
                        data: [35, 25, 16, 45, 15, 55, 45, 90, 60, 60, 22, 21]
                    }
                ]
            },
            budgetPlan: [],
            budgetFact: []
        };
    },
    methods: {
        numberWithCommas(x) {
            return parseFloat(x).toFixed(1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\u00a0');
        },
        numberWithCommasMln(x) {
            return (parseFloat(x) / 1000).toFixed(1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\u00a0');
        },
        filterChange(filt) {
            this.filter = filt;
        },
        async loadFactBudget(year, month, region, variant, resource) {
            try {
                const response = await fetch('/api-py/get-budgetfact219/'
                    + year + '/' + month + '/' + region + '/' + variant + '/' + resource);
                this.budgetFact = await response.json();
            } catch (err) {
                this.makeToast('danger', err.toString(), 'Ошибка запроса');
            }
        },
        async loadPlanBudget(year, month, region, variant, resource) {
            try {
                const response = await fetch('/api-py/get-budgetplan/'
                + year + '/' + month + '/' + region + '/' + variant + '/' + resource);
                this.budgetPlan = await response.json();
            } catch (err) {
                that.makeToast('danger', err.toString(), 'Ошибка запроса');
            }
        }
    },
    computed: {
        data1() {
            let all_fact = 0;
            let all_plan = 0;

            function mergeArray(array) {
                function getLastFact(arr, month, region_name) {
                    for (let j = month; j >= 1; j--) {
                        const r = arr.reduce(function (i, e) {
                            if ((e.region_name === region_name) & (e.month === j)) {
                                i = i + e.all_sum;
                            }
                            return i;
                        }, 0);
                        if (r > 0) {
                            return r;
                        }
                    }
                    return 0;
                }

                const o = array.reduce(function (init, each) {
                    if (each.month === 12) {
                        const ex = init.get(each.region_name);
                        if (ex) {
                            ex.plan += each.plan;
                            all_plan += each.plan;
                        } else {
                            const calc = getLastFact(array, 12, each.region_name);
                            init.set(each.region_name, { fact: calc, plan: each.plan });
                            all_fact += calc;
                            all_plan += each.plan;
                        }
                    }
                    return init;
                }, new Map());
                const r = [];
                o.forEach(function (value1, value2, set) {
                    const d = {
                        region: value2,
                        plan: (value1.plan / divider).toFixed(2),
                        fact: (value1.fact / divider).toFixed(2),
                        percent: (value1.fact / value1.plan * 100).toFixed(2)
                    };
                    r.push(d);
                });
                const sum = {
                    region: 'Итого',
                    plan: (all_plan / divider).toFixed(2),
                    fact: (all_fact / divider).toFixed(2),
                    percent: (all_fact / all_plan * 100).toFixed(2)
                };
                const result = {
                    data: r,
                    sum: sum
                };
                return result;
            }

            const res = mergeArray(data);
            return res;
        },
        totalPlan() {
            return this.budgetPlan.reduce((newArr, item) => {
                return Number((parseFloat(newArr + item.plan)).toFixed(1));
            }, 0);
        },
        totalFact() {
            return this.budgetPlan.reduce((newArr, item) => {
                return Number((parseFloat(newArr + item.fact)).toFixed(1));
            }, 0);
        },
        totalPercent() {
            return Number(parseFloat(this.budgetPlan.reduce((newArr, item) => {
                return Number(parseFloat(newArr + item.percent).toFixed(1));
            }, 0) / this.budgetPlan.length).toFixed(1));
        },
        myStyles() {
            return {
                height: `${this.height}%`,
                position: 'relative'
            };
        }
    },
    mounted() {
        this.loadPlanBudget(2020, 10, 430101, 2, 1);
    }
};
</script>

<style scoped>
.my-chart {
    width: 70%;
    margin-left: 20%;
}

.my-bar-chart {
    width: 60%;
    margin-left: 10%;
}

.my-table-percent {
    background-color: black;
    color: #00b050;
    max-width: 60px;
}

.nameOfTheClass {
    max-width: 60px;
}
</style>