




















import Class from './news-monitor';
export default Class;
