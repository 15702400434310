var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({
        'width': '100%',
        'height': '100%',
        'overflow': 'hidden'
    })},[_c('iframe',{style:({
            'width': '100%',
            'height': '100%',
            'overflow': 'auto'
        }),attrs:{"src":"https://stm.alem.kz/#/dashboard/403/max_post_published_at/full"}})])}
var staticRenderFns = []

export { render, staticRenderFns }