import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class CIndicator extends Vue {
    @Prop({
        required: true,
        default: []
    })
    private data!: any[];

    @Prop({
        required: true,
        default: ''
    })
    private year!: any;

    private getData = this.data;

    private getYear = parseInt(this.year);

    private get getResult() {
        const result: any[] = [];
        this.data.forEach((item) => {
            let yearing = null;
            item.items.forEach((year: any) => {
                const dating = new Date(year.date);
                const getYear = dating.getFullYear();
                if (this.getYear === getYear) {
                    yearing = getYear;
                }
            });
            const datas = {
                id: item.id,
                indicName: item.indicName,
                unitName: item.unitName,
                indicDate: yearing
            };
            result.push(datas);
        });
        return result;
    }
}