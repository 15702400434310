<template>
    <b-form>
        <div class="card-loader-body">
            <b-row>
                <b-col cols="12" lg="6">
                    <b-form-group id="input-gr-1" label="Наименование:" label-for="input-21">
                        <b-form-input
                            id="input-21"
                            @keydown.stop.prevent="keyEvent($event)"
                            @keyup.stop.prevent="keyEvent($event)"
                            @keypress.stop.prevent="keyEvent($event)"
                            v-model="propData.name"
                            placeholder=""
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="12" lg="6">
                    <b-form-group readonly id="input-gr-2" label="Дата формирования отчета:" label-for="input-22">
                        <b-form-input
                            id="input-22"
                            @keydown.stop.prevent="keyEvent($event)"
                            @keyup.stop.prevent="keyEvent($event)"
                            @keypress.stop.prevent="keyEvent($event)"
                            v-model="propData.data"
                            placeholder=""
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="12" lg="3">
                    <b-form-group id="input-gr-4" label="Регион (область, город, район):" label-for="input-24">
                        <b-form-input
                            id="input-24"
                            @keydown.stop.prevent="keyEvent($event)"
                            @keyup.stop.prevent="keyEvent($event)"
                            @keypress.stop.prevent="keyEvent($event)"
                            v-model="propData.region"
                            placeholder=""
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="12" lg="3">
                    <b-form-group id="input-gr-3" label="Единица измерения:" label-for="input-23">
                        <b-form-input
                            id="input-23"
                            @keydown.stop.prevent="keyEvent($event)"
                            @keyup.stop.prevent="keyEvent($event)"
                            @keypress.stop.prevent="keyEvent($event)"
                            v-model="propData.mesuare"
                            placeholder=""
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="12" lg="3">
                    <b-form-group id="input-gr-5" label="Период:" label-for="input-25">
                        <b-form-input
                            id="input-25"
                            @keydown.stop.prevent="keyEvent($event)"
                            @keyup.stop.prevent="keyEvent($event)"
                            @keypress.stop.prevent="keyEvent($event)"
                            v-model="propData.period"
                            placeholder=""
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="12" lg="3">
                    <b-form-group id="input-gr-6" label="Код дохода:" label-for="input-26">
                        <b-form-input
                            id="input-26"
                            @keydown.stop.prevent="keyEvent($event)"
                            @keyup.stop.prevent="keyEvent($event)"
                            @keypress.stop.prevent="keyEvent($event)"
                            v-model="propData.kod_profit"
                            placeholder=""
                        ></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
        </div>
        <div class="card-loader-bottom">
            <b-button variant="success" @click="CheckHash()">Загрузить</b-button>
            <b-button variant="default" @click="onClear()">Отмена</b-button>
        </div>
        <b-tabs class="tabs-in-tabs">
            <b-tab title="Загружаемые данные">
                <div class="table-container">
                    <b-table id="my-table-body" sticky-header bordered head-variant="light"
                             :items="fileBody"
                             :fields="fields"
                             :table-variant="light">
                        <template #cell(code)="row" class="tabBody">
                            <div align="center" class="code_text">
                                {{ row.item.code }}
                            </div>
                        </template>
                        <template #cell(org)="row">
                            <div align="left" class="code_text">
                                {{ row.item.org }}
                            </div>
                        </template>
                        <template #cell(sum)="row">
                            <div align="right" class="code_text">
                                {{ row.item.sum }}
                            </div>
                        </template>
                    </b-table>
                </div>
            </b-tab>
            <b-tab title="Журнал загрузки">
                <journal-table></journal-table>
            </b-tab>
        </b-tabs>
    </b-form>
</template>

<script>
import '../BudgetView.scss'
import {Ax} from "@/utils";
import JournalTable from "@/modules/budget/components/Journal";
import store from "@/services/store";

export default {
    name: 'PdfLoader243',
    components: { JournalTable },
    props: {
        propData: {},
        fileBody: {},
        fileName: {},
        file: {}
    },
    data() {
        return {
            light: 'light',
            fields: [
                { key: 'code', label: 'Код бюджетной классификации', sortable: false },
                { key: 'org', label: 'ИИН/БИН налогоплательщика', sortable: false },
                { key: 'sum', label: 'Сумма поступления', sortable: false }
            ]
        };
    },
    methods: {
        userLogin() {
            return store.state.user.login;
        },
        async CheckHash() {
            const formData = new FormData();
            formData.append('file', this.file, this.fileName);
            const response = await fetch('/api-py/check-hash', {
                method: 'POST',
                body: formData
            });
            const result = await response.json();
            console.log(result);
            if (result === true) {
                this.handleOk();
            } else {
                this.on243Load(formData);
            }
        },
        handleOk() {
            const formData = new FormData();
            formData.append('file', this.file, this.fileName);
            this.$bvModal.msgBoxConfirm('Сохранение приведет к изменению ранее принятых данных', {
                title: 'Подтвердите',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Сохранить',
                cancelTitle: 'Отменить',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    console.log('Save', value);
                    if (value) {
                        this.on243Load(formData);
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        },
        on243Load(formData) {
            const user = this.userLogin();
            this.$emit('save-load243', true);
            Ax(
                {
                    url: '/api-py/save-b243/'+ user,
                    method: 'POST',
                    data: formData
                }
                // (data) => {
                //     console.log('File243 uploaded successfully');
                //     this.makeToast('success', 'Файл сохранен !', 'Результат');
                //     this.$emit('save-load243', false);
                // },
                // (error) => {
                //     this.error = error;
                //     console.log('File243 not loaded');
                //     this.makeToast('danger', 'Ошибка сохранения !', 'Результат');
                //     this.$emit('save-load243', false);
                // }
            );
            this.makeToast('success', 'Загрузка файла начата! Результат в журнале Загрузки', 'Результат');
            this.$emit('save-load243', false);
        },
        // eslint-disable-next-line no-shadow
        onClear(event) {
            this.$emit('form-clear243', []);
            this.propData.name = '';
            this.propData.data = '';
            this.propData.mesuare = '';
            this.propData.region = '';
            this.propData.period = '';
            this.propData.kod_profit = '';
        },
        makeToast(variant = null, tostbody, title) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                autoHideDelay: 2000,
                solid: true
            })
        },
        keyEvent(event) {
        }
    }
}

</script>

<style scoped>
    .code_text {
        font-size: 0.8em;
    }

    .tabBody {
        font-weight: bold;
        font-style: italic;
        font-size: 1.8em;
        background-color: white;
    }
</style>