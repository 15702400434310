



























































































import { Component, Vue, Prop } from 'vue-property-decorator';
import ProgramFilter from '@/modules/program-monitor2/components/program-filter.vue';
import BTablePag from '@/modules/program-monitor2/components/b-table-pag.vue';
import CChartWidget from '@/modules/program-monitor2/components/charts/c-chart-widget.vue';

@Component({
    name: 'c-indic-tab',
    components: {
        'c-prog-filter': ProgramFilter,
        'b-table-pag': BTablePag,
        'c-widg-chart': CChartWidget
    }
})
export default class IndicTab extends Vue {
    @Prop({
        required: true,
        default: { id: -1, name: '' }
    })
    private curProg!: any;

    @Prop({
        required: true,
        default: null
    })
    private directBaseData!: any | null;

    @Prop({
        required: true,
        default: null
    })
    private sphereData!: any | null;

    @Prop({
        required: true,
        default: null
    })
    private katoBase!: any[] | null;

    @Prop({
        required: true,
        default: false
    })
    private active!: boolean;
    private progress = 0;

    private indicBaseData: any[] = []; // данные из базы по показателям
    private yearLst: any[] = [];
    private curYear: any | null = { val: 2015 };
    private curDirection: any | null = null;
    private curSphere: any | null = null;
    // ------diagram
    private diagramData: any[] | null = null;
    private diagramColor = ['#459ED8', '#6c757d', '#dc3545'];
    private reflowTrig = 1; // значения 1 и -1 отслеживается изменение
    private chartVisible = false;
    private curKato: any = { name: 'Все', code: null, id: null };

    private showModal = false;
    private editedIndic: any | any = null; // редактируемая запись

    public pgRows = 0;
    public curPage = 1;
    public perPage = 10;

    private sortBy = 'indicName';
    private sortDesc = false;
    private fields = [
        {
            key: 'indicName',
            label: 'Показатель (индикатор)',
            sortable: true
        },
        {
            key: 'unitName',
            label: 'Единицы измерения',
            sortable: true,
            tdClass: 'nameOfTheClass'
        },
        {
            key: 'plan',
            label: 'План',
            sortable: true
        },
        {
            key: 'fact',
            label: 'Факт',
            sortable: true
        },
        {
            key: 'periodStr',
            label: 'Период факта',
            sortable: true
        },
        {
            key: 'procProgress',
            label: 'Процент достижения',
            sortable: true
        },
        {
            key: 'risk',
            label: 'Риск недостижения',
            sortable: true
        },
        {
            key: 'executor',
            label: 'Ответственные за исполнение',
            sortable: true
        },
        {
            key: 'katoName',
            label: 'Территория',
            sortable: true
        },
        {
            key: 'more',
            label: ''
        }
    ];


    /* private get pageData() {
        let begIndx = (this.curPage - 1) * this.perPage;
        if (begIndx >= this.indicData.length) { begIndx = this.indicData.length - 1; }
        const endIndx = begIndx + this.perPage;
        if (endIndx >= this.indicData.length) {
            return this.indicData.slice(begIndx);
        }
        return this.indicData.slice(begIndx, endIndx);
    } */

    private get directionLst(): any[] {
        return this.getDictLangArr(this.directBaseData, 'direction', this.indicBaseData);
    }

    private get sphereLst(): any[] {
        return this.getDictLangArr(this.sphereData, 'sphere', this.indicData);
    }

    private get katoLst() {
        let result: any[] = [{ code: null, id: null, name: 'Все' }];
        if (!this.katoBase) { return result; }
        result = this.getDictLangArr(this.katoBase, 'statKato', this.indicBaseData);
        result.unshift({ code: null, id: null, name: 'Все' });
        return result;
    }

    private getDictLangArr(data: any[], fieldName: string, indicData: any[]) { // получение массива по справочникам с учётом языка
        const dictArr: any[] = [];
        for (const el of data) {
            const newObj: any = Object.assign({}, el);
            if (this.$i18n.locale === 'kk') { newObj.name = newObj.name_kz; } else { newObj.name = newObj['name_' + this.$i18n.locale]; }
            newObj.src = '';
            dictArr.push(newObj);
        }

        if (indicData !== null) {
            const res = [];
            for (const el of indicData) {
                if (el[fieldName] !== undefined && el[fieldName] !== null) {
                    for (let i = 0; i < dictArr.length; i++) {
                        const d = dictArr[i];
                        if (d.id === el[fieldName].id) {
                            res.push(d);
                            dictArr.splice(i, 1);
                            break;
                        }
                    }
                }
            }
            return res;
        }
        return dictArr;
    }

    private chgCurDir(val: null | any) {
        this.curSphere = null;
        this.curDirection = val;
    }
    private chgCurSph(val: null | any) {
        this.curSphere = val;
    }
    private chgCurY(val: null | any) {
        this.curYear = val;
    }

    private chgKato(val: any) {
        this.curKato = val;
    }

    private async loadProgData() {
        if (this.curProg === null) { return; }
        this.progress = 35;
        this.curPage = 1;
        this.curDirection = null;
        this.curSphere = null;
        // let sphere = '';
        // let direct = '';
        // if (this.curSphere !== null) { sphere = '&sphere=' + this.curSphere.id; }
        // if (this.curDirection !== null) { direct = '&direction=' + this.curDirection.id; }
        const result = await fetch(`/api/forecast?program=${this.curProg.id}`) // + sphere + direct)
            .then(response => response.json());
        this.progress = 75;
        this.getYearLst(result);
        this.indicBaseData = result;
        this.calcProc();
        this.progress = 100;
    }

    private getYearLst(data: any[]) { // получение списка годов из данных
        const result = [];
        this.yearLst = [];
        for (const el of data) {
            if (el.years !== null) {
                for (const y of el.years) {
                    result.push(y.year);
                }
            }
        }
        result.sort(function (d1: number, d2: number) { return (d1 - d2); });
        for (const el of result) {
            if (this.yearLst.length < 1 || this.yearLst[this.yearLst.length - 1].val !== el) {
                this.yearLst.push({ val: el });
            }
        }
        if (this.yearLst.length > 0) { this.curYear = this.yearLst[this.yearLst.length - 1]; } else { this.curYear = null; }
    }

    private calcProc() {
        for (const el of this.indicBaseData) {
            const character = el.indicator.character;
            if (el.years !== null) {
                for (const y of el.years) {
                    let procProgress = null;
                    let charProgress = 0;
                    if (y.plan !== null && y.fact !== null) {
                        if (y.plan !== 0) { procProgress = Math.round(y.fact / y.plan * 10000) / 100; }
                        y.procProgress = procProgress;
                        if (character !== 0 && procProgress !== null) {
                            if (procProgress * character >= 100) { charProgress = 1; } else { charProgress = -1; }
                            y.charProgress = charProgress;
                        }
                    }
                }
            }
        }
    }


    private get indicData(): any[] {
        const locale = this.$i18n.locale;
        const result: any[] = [];
        this.diagramData = null;
        this.pgRows = 0;
        if (this.indicBaseData === null || this.indicBaseData.length === 0) { return []; }
        this.diagramData = [{ name: 'Достигнуты', y: 0 }, { name: 'Ожидается', y: 0 }, { name: 'Риск', y: 0 }];
        for (const el of this.indicBaseData) {
            if (this.checkFilter(el)) {
                const obj: any = { id: el.id, indicName: '', unitName: '', factPeriod: '', note: '', factors: [], executor: el.executor, procProgress: null, plan: null, fact: null, sphere: el.sphere, risk: null, periodStr: '', katoName: null };
                if (el.indicatorCustomName !== null && locale === 'ru') {
                    obj.indicName = el.indicatorCustomName;
                } else if (el.indicatorCustomNameKz !== null && locale === 'kk') {
                    obj.indicName = el.indicatorCustomNameKz;
                } else if (el.indicatorCustomNameEn !== null && locale === 'en') {
                    obj.indicName = el.indicatorCustomNameEn;
                } else if (el.indicator !== null) {
                    if (locale === 'kk') { obj.indicName = el.indicator.name_kz; } else { obj.indicName = el.indicator['name_' + locale]; }
                }
                if (el.statKato && el.statKato.code) {
                    if (el.statKato.name_kz && locale === 'kk') {
                        obj.katoName = `${el.statKato.code} - ${el.statKato.name_kz}`;
                    } else {
                        obj.katoName = `${el.statKato.code} - ${el.statKato.name_ru}`;
                    }
                }
                if (el.statUnit !== null) {
                    if (locale === 'kk') { obj.unitName = el.statUnit.name_kz; } else { obj.unitName = el.statUnit['name_' + locale]; }
                }
                if (el.years !== null && this.curYear !== null) {
                    for (const y of el.years) {
                        if (y.year === this.curYear.val) {
                            if (y.risk !== undefined) { obj.risk = y.risk; }
                            obj.plan = y.plan;
                            obj.fact = y.fact;
                            if (obj.fact !== null && obj.fact !== undefined) { obj.fact = Math.round(obj.fact * 10) / 10; }
                            obj.procProgress = y.procProgress;
                            obj.charProgress = y.charProgress;
                            let periodStr = '';
                            if (y.startMonth !== null) { periodStr = this.getNameMonth(y.startMonth); }
                            if (y.endMonth !== null && y.endMonth !== y.startMonth) { periodStr += '-' + this.getNameMonth(y.endMonth); }
                            obj.periodStr = periodStr;
                        }
                    }
                }
                if (obj.procProgress >= 100) { this.diagramData[0].y++; } else if (obj.risk === true) { this.diagramData[2].y++; } else if (obj.risk === false) { this.diagramData[1].y++; }
                if (obj.plan !== null) { result.push(this.setProcProgress(obj)); }
            }
        }
        this.pgRows = result.length;
        return result;
    }

    private getNameMonth(month: number): string {
        const dt = new Date('01.01.2000');
        dt.setMonth(month - 1);
        return dt.toLocaleString('ru', { month: 'long' });
    }

    private setProcProgress(el: any) { // получить индикатор прогресса
        const result = Object.assign({ isActive: true }, el);
        if (el.charProgress === 1) { result._cellVariants = { procProgress: 'progress-reach' }; } else if (el.charProgress === -1) { result._cellVariants = { procProgress: 'progress-not-reach' }; }
        return result;
    }


    private checkFilter(indicObj: any) {
        if (this.curSphere !== null) {
            if (indicObj.sphere === null) { return false; }
            if (indicObj.sphere.id !== this.curSphere.id) { return false; }
        }
        if (this.curDirection !== null) {
            if (indicObj.direction === null) { return false; }
            if (indicObj.direction.id !== this.curDirection.id) { return false; }
        }
        if (this.curKato && this.curKato.id) {
            if (!indicObj.statKato) { return false; }
            if (indicObj.statKato.id !== this.curKato.id) { return false; }
        }
        return true;
    }

    private clkExec(row: any) {
        this.editedIndic = Object.assign({}, row);
        this.showModal = true;
    }

    private async saveExec() {
        await fetch('/api/forecast/' + this.editedIndic.id + '?executor=' + encodeURIComponent(this.editedIndic.executor), { method: 'PUT' });
        // eslint-disable-next-line
        for (let i = 0; i < this.indicBaseData.length; i++) {
            const el = this.indicBaseData[i];
            if (el.id === this.editedIndic.id) {
                el.executor = this.editedIndic.executor;
                break;
            }
        }
        this.editedIndic = null;
        this.showModal = false;
    }

    private mounted() {
        // eslint-disable-next-line
        const that = this;
        this.loadProgData();
        this.$watch('curProg', this.loadProgData);
        this.$watch('active', function () {
            if (that.active) {
                that.reflowTrig = that.reflowTrig * (-1);
            }
        });
        /* this.$watch('chartVisible', function () {
            if (that.chartVisible) { that.reflowTrig = that.reflowTrig * (-1); }
        }); */
    }
}
