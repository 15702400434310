















import Class from './c-panel';
export default Class;
