<template>
    <b-form>
        <div class="card-loader-body">
            <b-row>
                <b-col cols="12" lg="6">
                    <b-form-group id="input-gr-1" label="Наименование:" label-for="inp-21">
                        <b-form-input
                            id="inp-21"
                            @keydown.stop.prevent="keyEvent($event)"
                            @keyup.stop.prevent="keyEvent($event)"
                            @keypress.stop.prevent="keyEvent($event)"
                            v-model="propData.name"
                            placeholder=""
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="12" lg="6">
                    <b-form-group id="input-gr-2" label="Отчетный год:" label-for="inp-22">
                        <b-form-input
                            id="inp-22"
                            @keydown.stop.prevent="keyEvent($event)"
                            @keyup.stop.prevent="keyEvent($event)"
                            @keypress.stop.prevent="keyEvent($event)"
                            v-model="propData.year"
                            placeholder=""
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="12" lg="6">
                    <b-form-group id="input-gr-5" label="Отчетный месяц:" label-for="inp-25">
                        <b-form-input
                            id="inp-25"
                            @keydown.stop.prevent="keyEvent($event)"
                            @keyup.stop.prevent="keyEvent($event)"
                            @keypress.stop.prevent="keyEvent($event)"
                            v-model="propData.month"
                            placeholder=""
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="12" lg="3">
                    <b-form-group id="input-gr-3" label="Единица измерения:" label-for="inp-23">
                        <b-form-input
                            id="inp-23"
                            @keydown.stop.prevent="keyEvent($event)"
                            @keyup.stop.prevent="keyEvent($event)"
                            @keypress.stop.prevent="keyEvent($event)"
                            v-model="propData.mesuare"
                            placeholder=""
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="12" lg="3">
                    <b-form-group id="input-gr-4" label="Регион (область, город, район):" label-for="inp-24">
                        <b-form-input
                            id="inp-24"
                            @keydown.stop.prevent="keyEvent($event)"
                            @keyup.stop.prevent="keyEvent($event)"
                            @keypress.stop.prevent="keyEvent($event)"
                            v-model="propData.region"
                            placeholder=""
                        ></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
        </div>
        <div class="card-loader-bottom">
            <b-button variant="success" @click="checkHash()">Загрузить</b-button>
            <b-button variant="default" @click="onClear()">Отмена</b-button>
        </div>
        <b-tabs class="tabs-in-tabs">
            <b-tab title="Загружаемые данные">
                <div class="table-container">
                    <b-table id="my-table-body" sticky-header bordered head-variant="light"
                             :items="fileBody"
                             :fields="fields"
                             :table-variant="light">
                        <template #cell(code)="row" class="tabBody">
                            <div align="center" class="code_text">
                                {{ row.item.code }}
                            </div>
                        </template>
                        <template #cell(name)="row">
                            <div align="left" class="code_text">
                                {{ row.item.name }}
                            </div>
                        </template>
                        <template #cell(region)="row">
                            <div align="center" class="code_text">
                                {{ row.item.region }}
                            </div>
                        </template>
                        <template #cell(region_name)="row">
                            <div align="left" class="code_text">
                                {{ row.item.region_name }}
                            </div>
                        </template>
                        <template #cell(reg_sum)="row">
                            <div align="center" class="code_text">
                                {{ row.item.reg_sum }}
                            </div>
                        </template>
                        <template #cell(obl_sum)="row">
                            <div align="center" class="code_text">
                                {{ row.item.obl_sum }}
                            </div>
                        </template>
                        <template #cell(msu_sum)="row">
                            <div align="right" class="code_text">
                                {{ row.item.msu_sum }}
                            </div>
                        </template>
                    </b-table>
                </div>
            </b-tab>
            <b-tab title="Журнал загрузки">
                <journal-table></journal-table>
            </b-tab>
        </b-tabs>
    </b-form>
</template>

<script>
import '../BudgetView.scss'
import {Ax} from "@/utils";
import JournalTable from "@/modules/budget/components/Journal";
import store from "@/services/store";

export default {
    name: 'PdfLoader',
    components: { JournalTable },
    props: {
        propData: {},
        fileBody: {},
        fileName: {},
        file: {}
    },
    data() {
        return {
            light: 'light',
            fields: [
                {key: 'code', label: 'Код бюджетной классификации', sortable: false},
                {key: 'name', label: 'Наименование', sortable: false},
                {key: 'region_id', label: 'Код региона', sortable: false},
                {key: 'region_name', label: 'Наименование региона', sortable: false},
                {key: 'plan_reg', label: 'Бюджет района (города обл. значения) с нач.года', sortable: false},
                {key: 'plan_obl', label: 'Обл.бюд./г.Астаны или г.Алматы с нач.года', sortable: false},
                {key: 'plan_msu', label: 'Бюджет МСУ с нач.года', sortable: false}
            ]
        };
    },
    methods: {
        userLogin() {
            return store.state.user.login;
        },
        async checkHash() {
            const formData = new FormData();
            formData.append('file', this.file, this.fileName);
            // console.log(this.formData)
            const response = await fetch('/api-py/check-hash', {
                method: 'POST',
                body: formData
            });
            const result = await response.json();
            // console.log(result)
            if (result === true) {
                this.handleOk();
            } else {
                this.on219Load(formData);
            }
        },
        handleOk() {
            const formData = new FormData();
            formData.append('file', this.file, this.fileName);
            console.log(this.fileName)
            this.$bvModal.msgBoxConfirm('Сохранение приведет к изменению ранее принятых данных', {
                title: 'Подтвердите',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Сохранить',
                cancelTitle: 'Отменить',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    console.log('Save', value);
                    if (value) {
                        this.on219Load(formData);
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        },
        on219Load(formData) {
            const user = this.userLogin();
            this.$emit('save-load219', true);
            Ax(
                {
                    url: '/api-py/save-b219/'+ user,
                    method: 'POST',
                    data: formData
                }
                // (data) => {
                //     console.log('File219 uploaded successfully');
                //     this.makeToast('success', 'Файл сохранен !', 'Результат');
                //     this.$emit('save-load219', false);
                // },
                // (error) => {
                //     this.error = error;
                //     console.log('File219 not loaded');
                //     this.makeToast('danger', 'Ошибка сохранения !', 'Результат');
                //     this.$emit('save-load219', false);
                // }
            );
            this.makeToast('success', 'Загрузка файла начата! Результат в журнале Загрузки', 'Результат');
            this.$emit('save-load219', false);
        },
        // eslint-disable-next-line no-shadow
        onClear(event) {
            console.log('onClear');
            this.$emit('form-clear219', []);
            this.propData.name = '';
            this.propData.data = '';
            this.propData.mesuare = '';
            this.propData.region = '';
            this.propData.rep_date = '';
            this.propData.month = '';
            this.propData.year = '';
        },
        makeToast(variant = null, tostbody, title) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                autoHideDelay: 2000,
                solid: true
            })
        },
        keyEvent(event) {
        }
    }
}

</script>

<style scoped>
    .code_text {
        font-size: 0.8em;
    }

    .tabBody {
        font-weight: bold;
        font-style: italic;
        font-size: 1.8em;
        background-color: white;
    }
</style>