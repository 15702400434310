<template>
    <group-container>
        <b-table :fields="tableCols">
            <template #head(code)="row">
                <div class="text-center">{{ $t ( row.label) }}</div>
            </template>
            <template #cell(code)="row">
                <div class="text-left">
                    {{ row.item.region }}
                </div>
            </template>
            <template #head(name)="row">
                <div class="text-center">{{ $t ( row.label) }}</div>
            </template>
            <template #cell(name)="row">
                <div class="text-left">
                    {{ row.item.region }}
                </div>
            </template>
            <template #head(plan)="row">
                <div class="text-center">{{ $t (row.label) }}</div>
            </template>
            <template #cell(plan)="row">
                <div class="text-right">
                    {{ numberWithCommas(row.item.plan) }}
                </div>
            </template>
            <template #head(fact)="row">
                <div class="text-center">{{ $t (row.label) }}</div>
            </template>
            <template #cell(fact)="row">
                <div class="text-right">
                    {{ numberWithCommas(row.item.fact) }}
                </div>
            </template>
            <template #head(percent)="row">
                <div class="text-center">{{ $t( row.label) }}</div>
            </template>
            <template #cell(percent)="row">
                <div class="text-right">
                    {{ numberWithCommas(row.item.percent) }}
                </div>
            </template>
            <template slot="custom-foot">
                <b-td variant="primary" class="text-left">{{ $t ('modules.budget.total') }}</b-td>
                <b-td variant="primary" class="text-right">123</b-td>
                <b-td variant="primary" class="text-right">123</b-td>
                <b-td variant="primary" class="text-right">123</b-td>
                <b-td variant="primary" class="text-right">123</b-td>
            </template>
        </b-table>
    </group-container>
</template>

<script>
import GroupContainer from '@/modules/budget/components/GroupContainer';

export default {
    name: 'ReceiptsTable',
    components: {GroupContainer},
    data () {
        return {
            tableCols: [
                {
                    key: 'code',
                    label: 'modules.budget.the_code',
                    sortable: true
                },
                {
                    key: 'name',
                    label: 'modules.budget.receipt_name',
                    sortable: true
                },
                {
                    key: 'plan',
                    label: 'modules.budget.plan,_thousand_tenge',
                    sortable: true,
                    variant: 'warning'
                },
                {
                    key: 'fact',
                    label: 'modules.budget.fact,_thousand_tenge',
                    sortable: true,
                    variant: 'info'
                },
                {
                    key: 'percent',
                    label: 'modules.budget.execution_percent',
                    sortable: true
                }
            ]
        }
    }
}
</script>

<style scoped>

</style>