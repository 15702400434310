




import { Component, Vue } from 'vue-property-decorator';
@Component({
    name: 'c-prog-charts'
})
export default class ProgramCharts extends Vue {
}
