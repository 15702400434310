<template>
    <b-form>
        <div class="card-loader-body">
            <b-row>
                <b-col cols="12" lg="6">
                    <b-form-group id="input-group" label="Наименование:" label-for="input-1">
                        <b-form-input
                            id="input-1"
                            @keydown.stop.prevent="keyEvent($event)"
                            @keyup.stop.prevent="keyEvent($event)"
                            @keypress.stop.prevent="keyEvent($event)"
                            v-model="propData.name"
                            placeholder=""
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="12" lg="6">
                    <b-form-group id="input-group-2" label="Вид бюджета:" label-for="input-2">
                        <b-form-input
                            id="input-2"
                            @keydown.stop.prevent="keyEvent($event)"
                            @keyup.stop.prevent="keyEvent($event)"
                            @keypress.stop.prevent="keyEvent($event)"
                            v-model="propData.vid"
                            placeholder=""
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="12" lg="3">
                    <b-form-group id="input-group-5" label="Источник финансирования:" label-for="input-5">
                        <b-form-input
                            id="input-5"
                            @keydown.stop.prevent="keyEvent($event)"
                            @keyup.stop.prevent="keyEvent($event)"
                            @keypress.stop.prevent="keyEvent($event)"
                            v-model="propData.source"
                            placeholder=""
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="12" lg="3">
                    <b-form-group id="input-group-4" label="Специфика:" label-for="input-4">
                        <b-form-input
                            id="input-4"
                            @keydown.stop.prevent="keyEvent($event)"
                            @keyup.stop.prevent="keyEvent($event)"
                            @keypress.stop.prevent="keyEvent($event)"
                            v-model="propData.spec"
                            placeholder=""
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="12" lg="3">
                    <b-form-group id="input-group-3" label="Дата:" label-for="input-3">
                        <b-form-input
                            id="input-3"
                            @keydown.stop.prevent="keyEvent($event)"
                            @keyup.stop.prevent="keyEvent($event)"
                            @keypress.stop.prevent="keyEvent($event)"
                            v-model="propData.data"
                            placeholder=""
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="12" lg="3">
                    <b-form-group id="input-group-6" label="Регион (область, город, район):" label-for="input-6">
                        <b-form-input
                            id="input-6"
                            @keydown.stop.prevent="keyEvent($event)"
                            @keyup.stop.prevent="keyEvent($event)"
                            @keypress.stop.prevent="keyEvent($event)"
                            v-model="propData.region"
                            placeholder=""
                        ></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
        </div>
        <div class="card-loader-bottom">
            <b-button variant="success" @click="CheckHash()">Загрузить</b-button>
            <b-button variant="default" @click="onClear()">Отмена</b-button>
        </div>
        <b-tabs class="tabs-in-tabs">
            <b-tab title="Загружаемые данные">
                <div class="table-container">
                    <b-table id="my-table-body" sticky-header bordered head-variant="light" :items="fileBody"
                             :fields="fields"
                             :table-variant="light">
                        <template slot="top-row" bgcolor="#bee5eb">
                            <td bgcolor="#bee5eb"></td>
                            <td align="center" bgcolor="#bee5eb">Итого по отчету</td>
                            <td bgcolor="#bee5eb"></td>
                            <td bgcolor="#bee5eb"></td>
                            <td bgcolor="#bee5eb"></td>
                            <td align="right" bgcolor="#bee5eb">{{ $n(totalExpenses) }}</td>
                        </template>
                        <template #cell(fond)="row" class="tabBody">
                            <div align="center" class="code_text">
                                {{ row.item.fond }}
                            </div>
                        </template>
                        <template #cell(adm)="row">
                            <div align="center" class="code_text">
                                {{ row.item.adm }}
                            </div>
                        </template>
                        <template #cell(pr)="row">
                            <div align="center" class="code_text">
                                {{ row.item.pr }}
                            </div>
                        </template>
                        <template #cell(pdpr)="row">
                            <div align="center" class="code_text">
                                {{ row.item.pdpr }}
                            </div>
                        </template>
                        <template #cell(spec)="row">
                            <div align="center" class="code_text">
                                {{ row.item.spec }}
                            </div>
                        </template>
                        <template #cell(sum)="row">
                            <div align="right" class="code_text">
                                {{ $n(row.item.sum) }}
                            </div>
                        </template>
                    </b-table>
                </div>
            </b-tab>
            <b-tab title="Журнал загрузки">
                <journal-table></journal-table>
            </b-tab>
        </b-tabs>
    </b-form>
</template>

<script>
import '../BudgetView.scss'
import {Ax} from "@/utils";
import JournalTable from "@/modules/budget/components/Journal";
import store from "@/services/store";
// форма 5-52
export default {
    name: 'ExpendLoader',
    components: { JournalTable },
    props: {
        propData: {},
        fileBody: {},
        fileName: {},
        file: {}
    },
    data() {
        return {
            light: 'light',
            fields: [
                {key: 'fond', label: 'Фонд ', sortable: false},
                {key: 'abp', label: 'Администратор', sortable: false},
                {key: 'prg', label: 'Программа', sortable: false},
                {key: 'ppr', label: 'Подпрограмма', sortable: false},
                {key: 'spf', label: 'Специфика', sortable: false},
                {key: 'sum', label: 'Расходы с начала года', sortable: false}
            ]
        };
    },
    methods: {
        userLogin() {
            return store.state.user.login;
        },
        async CheckHash() {
            const formData = new FormData();
            formData.append('file', this.file, this.fileName);
            const response = await fetch('/api-py/check-hash', {
                method: 'POST',
                body: formData
            });
            const result = await response.json();
            console.log(result)
            if (result === true) {
                this.handleOk();
            } else {
                this.on552Load(formData);
            }
        },
        handleOk() {
            const formData = new FormData();
            formData.append('file', this.file, this.fileName);
            this.$bvModal.msgBoxConfirm('Сохранение приведет к изменению ранее принятых данных', {
                title: 'Подтвердите',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Сохранить',
                cancelTitle: 'Отменить',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    console.log('Save', value);
                    if (value) {
                        this.on552Load(formData);
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        },
        on552Load(formData) {
            const user = this.userLogin();
            this.$emit('save-load552', true);
            Ax(
                {
                    url: '/api-py/save-b552/'+ user,
                    method: 'POST',
                    data: formData
                }
                // (data) => {
                //     console.log('File552 uploaded successfully');
                //     this.makeToast('success', 'Файл сохранен !', 'Результат');
                //     this.$emit('save-load552', false);
                // },
                // (error) => {
                //     this.error = error;
                //     console.log('File552 not loaded');
                //     this.makeToast('danger', 'Ошибка сохранения !', 'Результат');
                //     this.$emit('save-load552', false);
                // }
            );
            this.makeToast('success', 'Загрузка файла начата! Результат в журнале Загрузки', 'Результат');
            this.$emit('save-load552', false);
        },
        // eslint-disable-next-line no-shadow
        onClear(event) {
            this.$emit('form-clear552', []);
            this.propData.name = '';
            this.propData.vid = '';
            this.propData.data = '';
            this.propData.spec = '';
            this.propData.source = '';
            this.propData.region = '';
        },
        makeToast(variant = null, tostbody, title) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                autoHideDelay: 4000,
                solid: true
            })
        },
        // eslint-disable-next-line no-shadow,@typescript-eslint/no-empty-function
        keyEvent(event) {
        }
    },
    computed: {
        totalExpenses() {
            return this.fileBody.reduce((newArr, item) => {
                return Number((parseFloat(newArr + item.sum)).toFixed(1));
            }, 0);
        }
    }
};
</script>

<style scoped>
    .code_text {
        font-size: 0.8em;
    }

    .tabBody {
        font-weight: bold;
        font-style: italic;
        font-size: 1.8em;
        background-color: white;
    }
</style>