












































import { Component, Vue, Prop } from 'vue-property-decorator';
import IndicTab from '@/modules/program-monitor2/components/indic-tab.vue';
import ProgramPasport from '@/modules/program-monitor2/components/program-pasport.vue';
import ProgramExecution from '@/modules/program-monitor2/components/program-execution.vue';
import ProgramFinance from '@/modules/program-monitor2/components/program-finance.vue';
import CProgMethod from '@/modules/program-monitor2/components/efficiency/program-methodology.vue';
import CProgRatimg from '@/modules/program-monitor2/components/efficiency/program-rating.vue';
import CProgCalc from '@/modules/program-monitor2/components/efficiency/program-calc.vue';
import store from '@/services/store';
// import ProgramAnalys from '@/modules/program-monitor2/components/program-analys.vue';

@Component({
    components: {
        'c-indic-tab': IndicTab,
        'c-prog-pasport': ProgramPasport,
        'c-prog-exec': ProgramExecution,
        'c-prog-fin': ProgramFinance,
        'c-prog-method': CProgMethod,
        'c-prog-rating': CProgRatimg,
        'c-prog-calc': CProgCalc
        // 'c-prog-analys': ProgramAnalys,
    }
})
export default class ProgramCard extends Vue {
    @Prop({
        required: true,
        default: { id: -1, name: '' }
    })
    private curProg!: any;

    private directBaseData: any[] = []; // данные из базы по направлениям

    private katoBase: any[] = [];
    private oblKato= '55';


    private sphereData: any[] = []; // справочник по сферам (данные с базы)
    private tabIndex = 1;
    private eventTabIndx = 0;
    private effectTabIndx = 0;

    private pasportData: any | null = null;


    private async loadSphere() {
        this.sphereData = await fetch('/api/dict/sphere')
            .then(response => response.json());
    }

    private async loadDirectLst() {
        this.directBaseData = await fetch('/api/dict/direction')
            .then(response => response.json());
    }


    private created() {
        if (store.state._instanceCode) { this.oblKato = store.state._instanceCode; }
        // eslint-disable-next-line
        const that = this;
        this.loadSphere();
        this.loadDirectLst();
        this.loadKatoDict();
        this.$watch('curProg', function () {
            that.tabIndex = 1;
            that.eventTabIndx = 0;
        });
    }

    private async loadKatoDict() {
        this.katoBase = [];
        let result: any = [];
        try {
            result = await fetch(`/api-py/get-kato-by-obl/${this.oblKato}`);
            if (result.status === 200) {
                result = await result.json();
            } else {
                this.makeToast('danger', 'get-kato-by-obl', `${result.status} - ${result.statusText}`);
                result = [];
            }
        } catch (error) {
            result = [];
            this.makeToast('danger', 'Ошибка запроса get-kato-by-obl', error.toString());
        }

        if (result && result.length) {
            // eslint-disable-next-line @typescript-eslint/camelcase
            this.katoBase = result;
        }
    }

    private makeToast(variant: any, title: string, tostbody: any) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }

}
