
















import { Component, Vue } from 'vue-property-decorator';


interface IItemQuery extends Record<string, any>{
    id: number;
}

interface IItem {
    id: number;
    name: string;
    href: string;
    query?: IItemQuery;
}


let loaded = false;
let staticProgramData: any[] = [];


@Component
export default class ProgramLinkList extends Vue {
    private created() {
        if (loaded) {
            this.programData = staticProgramData;
        } else {
            this.loadProgram();
        }
    }


    private loading = false;

    private programData: any[] = [];

    private async loadProgram() {
        this.loading = true;
        try {
            this.programData = await fetch('/api/dict/program')
                .then(response => response.json());

            if (!loaded) {
                staticProgramData = this.programData;
                loaded = true;
            }
        } finally {
            this.loading = false;
        }
    }

    private get menuProgram(): IItem[] {
        const result: IItem[] = [{ id: -1, name: 'Общие показатели', href: '/program-monitor2' }];
        for (const el of this.programData) {
            const newObj: IItem = { href: '/program-monitor2', id: el.id, name: '', query: { id: el.id } };
            if (this.$i18n.locale === 'kk') {
                newObj.name = el.name_kz;
            } else {
                newObj.name = el['name_' + this.$i18n.locale];
            }
            // newObj.name = newObj.name_ru;
            result.push(newObj);
        }
        return result;
    }

    private getItemPath(item: IItem): string {
        return this.$router.resolve(
            {
                path: item.href,
                query: item.query
            },
            undefined,
            false
        ).href;
    }
}
