




















































































import { Component, Vue, Prop } from 'vue-property-decorator';

export interface IIndicCardData {
    id?: number | string;
    indicName: string; // имя показателя
    unitName?: string | null; // ед измерения
    plan?: number | null; // план
    fact?: number | null; // факт
    proc?: number | null; // процент достижения
}

@Component({
    name: 'c-indic-card-tab'
})
export default class CIndicCardTab extends Vue {
    private curIndx :number | null = null; // текущая строка

    @Prop({
        required: true,
        default: []
    })
    private data!: IIndicCardData[];

    private get getData() {
        for (const el of this.data) {
            el.proc = null;
            if (el.plan !== undefined && el.fact !== undefined && el.plan !== null && el.fact !== null) {
                if (el.plan !== 0) {
                    el.proc = Math.round(el.fact / el.plan * 10000) / 100;
                }
            }
        }
        return this.data;
    }

    private procColClk(indx: number){
        this.curIndx = indx;
        this.$emit('openModal', indx);
    }
}
