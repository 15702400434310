







































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
    name: 'c-prog-calc'
})
export default class CProgCalc extends Vue {
    @Prop({
        required: true,
        default: { id: -1, name: '' }
    })
    private curProg!: any;

    // показать/скрыть таблицы
    private showHideArr: any[] = [ { name: 'D', show: false }, { name: 'L', show: false }, { name: 'Z', show: false }, { name: 'G', show: false }, { name: 'O', show: false }, { name: 'N', show: false }, { name: 'W', show: false } ];
    private showHideAll = false;

    private curY: any | null = null; // текущий год

    private yearLst = [];

    private mounted() {
        this.getYearLst();
        this.$watch('curProg', this.getYearLst);
        this.$watch('curY', this.loadData);
    }

    private async loadData() {
        const params = 'program=' + this.curProg.id + '&year=' + this.curY.year;
        this.showHideArr[0].data = await fetch('api/assessment/d?' + params).then(response => response.json());
        this.showHideArr[1].data = await fetch('api/assessment/part/l?' + params).then(response => response.json());
        this.showHideArr[2].data = await fetch('api/assessment/part/z?' + params).then(response => response.json());
        this.showHideArr[3].data = await fetch('api/assessment/sheet/g?' + params).then(response => response.json());
        this.showHideArr[4].data = await fetch('api/assessment/sheet/o/total?sheet=o&' + params).then(response => response.json());
        this.showHideArr[5].data = await fetch('api/assessment/sheet/n/total?sheet=n&' + params).then(response => response.json());
        this.showHideArr[6].data = await fetch('api/assessment/part/w?' + params).then(response => response.json());
    }

    private showHideChg(indx: number) {
        this.showHideArr[indx].show = !this.showHideArr[indx].show;
        if (this.showHideArr[indx].show) { this.showHideAll = true; } else {
            let fl = false;
            for (const el of this.showHideArr) {
                if (el.show) { fl = true; }
            }
            if (!fl) { this.showHideAll = false; }
        }
    }

    private async getYearLst() {
        this.yearLst = [];
        const result = await fetch('api/assessment/filtering/year?program=' + this.curProg.id)
            .then(response => response.json());
        if (result !== undefined && result !== null) { this.yearLst = result; }
        if (this.yearLst.length > 0) {
            this.curY = this.yearLst[0];
            this.loadData();
        }
    }

    private showHideAllChg() {
        if (this.showHideAll) {
            for (const el of this.showHideArr) {
                el.show = false;
            }
        }
        this.showHideAll = false;
    }
}
