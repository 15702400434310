<template>
    <b-container class="sub-container" fluid="true">
        <h3 class="budget-header">{{ $t ('modules.budget.data_on_receipts') }}</h3>
        <filter-row :select-data="filter"></filter-row>
        <b-tabs>
            <template #tabs-start>
                <div class="receipts-panel">
                    <div class="left">
                        <img src="/img/panel/vector-0.png" alt="Empty">
                        {{ $t ('modules.budget.income_structure') }}
                    </div>
                    <div class="right">{{ $t('modules.budget.display_type') }}</div>
                </div>
            </template>
            <b-tab>
                <template #title>
                    <b-img src="/img/panel/vector-0.png"></b-img>
                </template>
                <receipts-table></receipts-table>
            </b-tab>
            <b-tab title="График"> <template #title>
                <b-img src="/img/panel/vector-0.png"></b-img>
            </template>
                <receipts-graph></receipts-graph>
            </b-tab>
        </b-tabs>
    </b-container>
</template>

<script>
import FilterRow from '@/modules/budget/components/FilterRow';
import '../BudgetView.scss';
import ReceiptsGraph from '@/modules/budget/components/ReceiptsGraph';
import ReceiptsTable from '@/modules/budget/components/ReceiptsTable';

export default {
    name: 'ReceiptsData',
    components: { FilterRow, ReceiptsGraph, ReceiptsTable },
    data() {
        return {
            filter: {}
        };
    }
};
</script>

<style scoped lang="scss">
.receipts-panel {
    color: red;
    background-color: #E3EEF6;
    height: 3em;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 8px;
    overflow: hidden;
    width: calc(100% - 120px);
    display: block;
    & .left {
        float: left;
        color: black;
        text-transform: uppercase;
        font-weight: 600;
        & img {
            width: 26px;
            height: 26px;
            margin-right: 40px;
        }
    }
    & .right {
        color: #5a80ad;
        float: right;
    }
}
</style>