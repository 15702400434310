import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class CPanel extends Vue {
    @Prop({
        required: true,
        default: { 'directName': '' }
    })
    private data!: any;

    public section = 0;

    public selectSection(value: number) {
        this.section = value;

        this.$emit('selectSection', { value });
    }
}