<template>
    <b-container class="sub-container">
        <h3 class="budget-header">Крупные плательщики</h3>
        <filter-row :select-data="filter"></filter-row>
    </b-container>
</template>

<script>
import FilterRow from '@/modules/budget/components/FilterRow';
import '../BudgetView.scss'

export default {
    name: 'LargePayers',
    components: {FilterRow},
    data () {
        return {
            filter: {}
        }
    }
}
</script>

<style scoped>

</style>