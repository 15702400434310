import { Vue, Component, Prop } from 'vue-property-decorator';

import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import Highcharts3D from 'highcharts/highcharts-3d';


HighchartsMore(Highcharts);
Highcharts3D(Highcharts);


@Component
export default class VariablePie extends Vue {
    @Prop({
        type: String,
        required: true
    })
    private percent!: string;

    @Prop({
        type: Array,
        required: true
    })
    private data!: Highcharts.SeriesVariablepieDataOptions[];

    @Prop()
    public readonly redrawTrigger!: any;

    private chart: Highcharts.Chart | undefined;
    private options: Highcharts.Options = {};

    private created() {
        this.$watch('data', this.dataChanged);
        this.$watch('percent', this.dataChanged);
        this.$watch('redrawTrigger', this.redrawTriggerChanged);
    }

    private mounted() {
        this.dataChanged();
    }

    private beforeDestroy() {
        if (this.chart !== undefined) {
            this.chart.destroy();
        }
    }

    private redrawTriggerChanged() {
        if (this.chart !== undefined) {
            this.chart.reflow();
        }
    }

    private updateOptions() {
        const colors = ['#459ED8', '#F79647'];

        if (this.data !== null || this.data !== undefined) {
            this.options = {
                colors,
                chart: {
                    type: 'variablepie'
                },
                title: {
                    verticalAlign: 'middle',
                    align: 'center',
                    floating: true,
                    text: this.percent + ' %',
                    style: {
                        color: '#223345',
                        fontSize: '1.8rem'
                    }
                },
                exporting: {
                    enabled: true,
                    menuItemDefinitions: {
                        printChart: {
                            text: 'Печать'
                        },
                        downloadPNG: {
                            text: 'Скачать PNG'
                        },
                        downloadJPEG: {
                            text: 'Скачать JPEG'
                        },
                        downloadPDF: {
                            text: 'Скачать PDF'
                        },
                        downloadSVG: {
                            text: 'Скачать SVG'
                        }
                    },
                    buttons: {
                        contextButton: {
                            menuItems: ['printChart', 'separator', 'downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG', 'label']
                        }
                    }
                },
                series: [{
                    type: 'variablepie',
                    innerSize: '75%',
                    size: '80%',
                    data: this.data,
                    dataLabels: {
                        enabled: false
                    }
                }],
                credits: {
                    enabled: false
                }
            };
        }
    }

    private dataChanged() {
        this.updateOptions();
        if (this.chart === undefined) {
            this.chart = Highcharts.chart(this.$el as HTMLElement, this.options);
        } else {
            this.chart.update(this.options);
        }
    }
}