import { Component, Vue } from 'vue-property-decorator';
import store from '@/services/store';

import ProgramCharts from '@/modules/program-monitor2/components/program-charts.vue';
import ProgramCard from '@/modules/program-monitor2/components/program-card.vue';

@Component({
    components: {
        'c-prog-charts': ProgramCharts,
        'c-prog-card': ProgramCard
    }
})
export default class ProgramMonitor2 extends Vue {
    public sectionData = {};
    private programDictData: any[] = []; // справочник программ (данные с базы)

    public created() {
        this.loadProgramDict();
    }


    private get curProg(): any {
        const progId = Number(this.$route.query.id);
        if (this.programDict === null) { return { id: -1, name: 'Общие показатели', src: '' }; }

        for (const el of this.programDict) {
            if (el.id === progId) {
                return { id: el.id, name: el.name };
            }
        }
        return { id: -1, name: 'Общие показатели', src: '' };
    }

    private async loadProgramDict() {
        this.programDictData = await fetch('/api/dict/program')
            .then(response => response.json());
    }

    private get programDict(): any[] {
        const dictArr: any[] = [{ id: -1, name: 'Общие показатели', src: '' }];
        for (const el of this.programDictData) {
            const newObj: any = Object.assign({}, el);
            if (this.$i18n.locale === 'kk') { newObj.name = newObj.name_kz; } else { newObj.name = newObj['name_' + this.$i18n.locale]; }
            newObj.src = '';
            dictArr.push(newObj);
        }
        return dictArr;
    }

    public get isAuthorized(): boolean {
        return store.state.user.authorized;
    }
}