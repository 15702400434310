















import Class from './chart-variable-pie';
export default Class;
