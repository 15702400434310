<template>
    <div class="inner-container">
        <div class="section-title budget"><i class="icon icon-calculator-coins"></i><span>Бюджетное планирование</span></div>
        <div class="section-subtitle"><i class="icon icon-grid"></i>Интеграции/загрузка</div>
        <b-tabs lazy>
            <b-tab title="План по доходам">
                <b-overlay id="over1" :show="busy1" rounded="sm" :opacity="0.8"
                           spinner-variant="primary"
                           spinner-type="grow"
                           spinner-small>
                    <div class="card-loader-top">
                        <b-row>
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    id="form-file-1"
                                    label="Файл для загрузки:"
                                    label-for="file"
                                    description="Выберите файл формата .xls, .xlsx"
                                >
                                    <b-form @submit.stop.prevent>
                                        <b-form-file id="file" accept=".xls, .xlsx, .pdf" placeholder="Выберите файл" v-model="file1"
                                                     ref="file-input" browse-text="Обзор" @input="inputFile1()">
                                        </b-form-file>
                                    </b-form>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </div>
                    <file-loader :prop-data="fileProp" :file-body="fileBody" :file-name="file1.name"
                                 :file="file1"
                                 v-on:form-clear="formClear" v-on:save-load="saveLoad"></file-loader>
                </b-overlay>
            </b-tab>
            <b-tab title="Форма 2-19">
                <b-overlay id="over2" :show="busy2" rounded="sm" :opacity="0.8"
                           spinner-variant="primary"
                           spinner-type="grow"
                           spinner-small>
                    <div class="card-loader-top">
                        <b-row>
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    id="form-file-2"
                                    label="Файл для загрузки:"
                                    label-for="file219"
                                    description="Выберите файл формата .pdf"
                                >
                                    <b-form @submit.stop.prevent>
                                        <b-form-file id="file219" accept=".xls, .xlsx, .pdf" placeholder="Выберите файл" v-model="file219"
                                                     ref="file-input" browse-text = "Обзор" @input="inputFile219()">
                                        </b-form-file>
                                    </b-form>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </div>
                    <pdf-loader :prop-data="fileProp219" :file-body="fileBody219" :file-name="file219.name"
                                :file="file219"
                                v-on:form-clear219="formClear219"  v-on:save-load219="saveLoad219"></pdf-loader>
                </b-overlay>
            </b-tab>
            <b-tab title="Форма 2-43">
                <b-overlay id="over3" :show="busy3" rounded="sm" :opacity="0.8"
                           spinner-variant="primary"
                           spinner-type="grow"
                           spinner-small>
                    <div class="card-loader-top">
                        <b-row>
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    id="form-file-2"
                                    label="Файл для загрузки:"
                                    label-for="file219"
                                    description="Выберите файл формата .pdf"
                                >
                                    <b-form @submit.stop.prevent>
                                        <b-form-file id="file243" accept=".xls, .xlsx, .pdf" placeholder="Выберите файл" v-model="file243"
                                                     ref="file-input" browse-text = "Обзор" @input="inputFile243()">
                                        </b-form-file>
                                    </b-form>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </div>
                    <pdf-loader243 :prop-data="fileProp243" :file-body="fileBody243" :file-name="file243.name"
                                   :file="file243"
                                   v-on:form-clear243="formClear243" v-on:save-load243="saveLoad243"></pdf-loader243>
                </b-overlay>
            </b-tab>
            <b-tab title="План по расходам">
                <b-overlay id="over5" :show="busy5" rounded="sm" :opacity="0.8"
                           spinner-variant="primary"
                           spinner-type="grow"
                           spinner-small>
                    <div class="card-loader-top">
                        <b-row>
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    id="form-file-5"
                                    label="Файл для загрузки:"
                                    label-for="file5"
                                    description="Выберите файл формата .xls, .xlsx"
                                >
                                    <b-form @submit.stop.prevent>
                                        <b-form-file id="file5" accept=".xls, .xlsx" placeholder="Выберите файл" v-model="file5"
                                                     ref="file-input" browse-text = "Обзор" @input="inputFile5()">
                                        </b-form-file>
                                    </b-form>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </div>
                    <exp-loader :prop-data="fileProp5" :file-body="fileBody5" :file-name="file5.name"
                                :file="file5"
                                v-on:form-clear="formClear5" v-on:save-load="saveLoad5"></exp-loader>
                </b-overlay>
            </b-tab>
            <b-tab title="Форма 5-52">
                <b-overlay id="over4" :show="busy4" rounded="sm" :opacity="0.8"
                           spinner-variant="primary"
                           spinner-type="grow"
                           spinner-small>
                    <div class="card-loader-top">
                        <b-row>
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    id="form-file-4"
                                    label="Файл для загрузки:"
                                    label-for="file552"
                                    description="Выберите файл формата .pdf"
                                >
                                    <b-form @submit.stop.prevent>
                                        <b-form-file id="file552" accept=".pdf" placeholder="Выберите файл" v-model="file552"
                                                     ref="file-input" browse-text = "Обзор" @input="inputFile552()">
                                        </b-form-file>
                                    </b-form>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </div>
                    <expend-loader :prop-data="fileProp552" :file-body="fileBody552" :file-name="file552.name"
                                   :file="file552"
                                   v-on:form-clear552="formClear552" v-on:save-load552="saveLoad552"></expend-loader>
                </b-overlay>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import { Ax } from '@/utils';
import FileLoader from '@/modules/budget/components/FileLoader';
import PdfLoader from '@/modules/budget/components/PDFLoader219';
import PdfLoader243 from '@/modules/budget/components/PDFLoader243';
import ExpendLoader from '@/modules/budget/components/ExpendLoader';
import ExpLoader from '@/modules/budget/components/ExpLoader';
export default {
    name: 'BudgetLoader',
    // eslint-disable-next-line vue/no-unused-components
    components: { ExpendLoader, FileLoader, PdfLoader, PdfLoader243, ExpLoader },
    data() {
        return {
            dangReg: 'Наименование',
            file1: [],
            file219: [],
            file243: [],
            file552: [],
            file5: [],
            fileHead: [],
            fileBody: [],
            fileHead5: [],
            fileBody5: [],
            fileHead219: [],
            fileBody219: [],
            fileHead243: [],
            fileBody243: [],
            fileHead552: [],
            fileBody552: [],
            fileName: false,
            fileProp: {
                name: '',
                vid: '',
                period: '',
                data: '',
                mesuare: '',
                region: ''
            },
            fileProp5: {
                name: '',
                vid: '',
                period: '',
                data: '',
                mesuare: '',
                region: ''
            },
            fileProp219: {
                name: '',
                data: '',
                mesuare: '',
                region: '',
                rep_date: ''
            },
            fileProp243: {
                name: '',
                period: '',
                data: '',
                mesuare: '',
                region: '',
                kod_profit: ''
            },
            fileProp552: {
                name: '',
                vid: '',
                spec: '',
                source: '',
                data: '',
                region: ''
            },
            busy1: false,
            busy2: false,
            busy3: false,
            busy4: false,
            busy5: false
        };
    },
    methods: {
        inputFile1() {
            const formData = new FormData();
            if (this.file1 !== null) {
                this.busy1 = true;
                formData.append('file', this.file1, this.file1.name);
                this.FilePPLoad(formData);
            }
        },
        async FilePPLoad (formData) {
            try {
                const response = await fetch('/api-py/check-hplan', {
                    method: 'POST',
                    credentials: 'include',
                    body: formData
                });
                const result = await response.json();
                if (result === true) {
                    await this.fileHeadLoad(formData);
                }
                else {
                    this.busy1 = false;
                    this.makeToast('danger', 'Выбранный файл не соответствует шаблону!', 'Сообщение');
                }
            } catch (err) {
                this.busy1 = false;
                this.makeToast('danger', err.toString(), 'Ошибка запроса');
            }
        },
        fileHeadLoad(formData) {
            try {
                Ax(
                    {
                        url: '/api-py/upload-hplan', // Заголовок файла
                        method: 'POST',
                        data: formData
                    },
                    (data) => {
                        this.busy1 = true;
                        this.fileHead = JSON.parse(data);
                        if (this.fileHead !== null) {
                            this.fileProp.name = this.fileHead[0].names + this.fileHead[1].names;
                            this.fileProp.vid =  this.fileHead[2].value;
                            this.fileProp.period =  this.fileHead[3].value;
                            this.fileProp.data =  this.fileHead[4].value;
                            this.fileProp.mesuare = this.fileHead[5].value;
                            if (this.fileHead[6].value !== this.dangReg) {
                                this.fileProp.region = this.fileHead[6].value + ' (' + this.fileHead[6].regId + ')';
                            } else {
                                this.fileProp.region = '';
                            }
                            this.fileBodyLoad(formData);
                        }

//                    this.busy1 = false;
                    },
                    (error) => {
                        this.error = error;
                        this.busy1 = false;
                    }
                );
            } catch (err) {
                this.busy1 = false;
                this.makeToast('danger', err.toString(), 'Ошибка запроса');
            }

        },
        fileBodyLoad(formData) {
            try {
                Ax(
                    {
                        url: '/api-py/upload-bplan', //  Тело файла
                        method: 'POST',
                        data: formData
                    },
                    (data) => {
                        this.busy1 = true;
                        this.fileBody = JSON.parse(data);
                        this.busy1 = false;
                    },
                    (error) => {
                        this.error = error;
                        this.busy1 = false;
                    }
                );
            } catch (err) {
                this.busy1 = false;
                this.makeToast('danger', err.toString(), 'Ошибка запроса');
            }
        },
        inputFile5() {
            const formData5 = new FormData();
            if (this.file5 !== null) {
                this.busy5 = true;
                formData5.append('file', this.file5, this.file5.name);
                this.File5Load(formData5);
            }
        },
        async File5Load (formData5) {
            try {
                const response = await fetch('/api-py/check-hfact', {
                    method: 'POST',
                    credentials: 'include',
                    body: formData5
                });
                const result = await response.json();
                console.log(result);
                if (result === true) {
                    await this.pdfLoadHead5(formData5);
                }
                else {
                    this.busy5 = false;
                    this.makeToast('danger', 'Выбранный файл не соответствует шаблону!', 'Сообщение');
                }
            } catch (err) {
                this.makeToast('danger', err.toString(), 'Ошибка запроса');
            }
        },
        pdfLoadHead5(formData5){
            try {
                Ax(
                    {
                        url: '/api-py/upload-hfact',  // Заголовок файла
                        method: 'POST',
                        data: formData5
                    },
                    (data) => {
                        this.busy5 = true;
                        this.fileHead5 = JSON.parse(data);
                        if (this.fileHead5 !== null) {
                            this.fileProp5.name = this.fileHead5[0].names + this.fileHead5[1].names;
                            this.fileProp5.vid = this.fileHead5[2].value;
                            this.fileProp5.period = this.fileHead5[3].value;
                            this.fileProp5.data = this.fileHead5[4].value;
                            this.fileProp5.mesuare = this.fileHead5[5].value;
                            if (this.fileHead5[6].value !== this.dangReg) {
                                this.fileProp5.region = this.fileHead5[6].value + ' (' + this.fileHead5[6].regId + ')';
                            } else {
                                this.fileProp5.region = '';
                            }
                            this.pdfLoadBody5(formData5);
                        }
                        //this.busy5 = false;
                    },
                    (error) => {
                        this.error = error;
                        this.busy5 = false;
                    },
                );
            } catch (err) {
                this.busy5 = false;
                this.makeToast('danger', err.toString(), 'Ошибка запроса');
            }
        },
        pdfLoadBody5(formData5){
            try {
                Ax(
                    {
                        url: '/api-py/upload-bfact', //  Тело файла
                        method: 'POST',
                        data: formData5
                    },
                    (data) => {
                        this.busy5 = true;
                        this.fileBody5 = JSON.parse(data);
                        this.busy5 = false;
                    },
                    (error) => {
                        this.error = error;
                        this.busy5 = false;
                    }
                );
            } catch (err) {
                this.busy5 = false;
                this.makeToast('danger', err.toString(), 'Ошибка запроса');
            }


},
        inputFile219() {
            const formData219 = new FormData();
            if (this.file219 !== null) {
                this.busy2 = true;
                formData219.append('file', this.file219, this.file219.name);
                this.File219Load(formData219);
            }
        },
        async File219Load (formData219) {
            try {
                const response = await fetch('/api-py/check-h219', {
                    method: 'POST',
                    credentials: 'include',
                    body: formData219
                });
                const result = await response.json();
                console.log(result);
                if (result === true) {
                    await this.pdfLoadHead(formData219);
                }
                else {
                    this.busy2 = false;
                    this.makeToast('danger', 'Выбранный файл не соответствует шаблону!', 'Сообщение');
                }
            } catch (err) {
                this.busy2 = false
                this.makeToast('danger', err.toString(), 'Ошибка запроса');
            }
        },
        pdfLoadHead(formData219) {
            try {
                Ax(
                    {
                        url: '/api-py/upload-h219',  // Заголовок файла
                        method: 'POST',
                        data: formData219
                    },
                    (data) => {
                        // запуск обновления данных о пользователе в общем хранилище
                        // возврат на предыдущую страницу
                        this.fileHead219 = JSON.parse(data);
                        this.fileProp219.name = this.fileHead219[0].fileName;
                        this.fileProp219.data = this.fileHead219[0].data;
                        this.fileProp219.mesuare = this.fileHead219[0].mesuare;
                        this.fileProp219.region = this.fileHead219[0].region;
                        this.fileProp219.rep_date = this.fileHead219[0].rep_date;
                        this.fileProp219.month = this.fileHead219[0].month;
                        this.fileProp219.year = this.fileHead219[0].year;
                        this.pdfLoadBody(formData219);
                    },
                    (error) => {
                        this.error = error;
                    }
                );
            } catch (err) {
                this.busy2 = false;
                this.makeToast('danger', err.toString(), 'Ошибка запроса');
            }


        },
        pdfLoadBody(formData219){
            try {
                Ax(
                    {
                        url: '/api-py/upload-b219', //  Тело файла
                        method: 'POST',
                        data: formData219
                    },
                    (data) => {
                        this.busy2 = true;
                        this.fileBody219 = JSON.parse(data);
                        this.busy2 = false;
                    },
                    (error) => {
                        this.error = error;
                        this.busy2 = false;
                    }
                );
            } catch (err) {
                this.busy2 = false;
                this.makeToast('danger', err.toString(), 'Ошибка запроса');
            }

        },
        inputFile243() {
            const formData243 = new FormData();
            if (this.file243 !== null) {
                this.busy3 = true;
                formData243.append('file', this.file243, this.file243.name);
                this.File243Load(formData243);
            }
        },
        async File243Load (formData243) {
            try {
                const response = await fetch('/api-py/check-h243', {
                    method: 'POST',
                    credentials: 'include',
                    body: formData243
                });
                const result = await response.json();
                console.log(result);
                if (result === true) {
                    await this.pdfLoadHead243(formData243);
                }
                else {
                    this.busy3 = false;
                    this.makeToast('danger', 'Выбранный файл не соответствует шаблону!', 'Сообщение');
                }
            } catch (err) {
                this.busy3 = false;
                this.makeToast('danger', err.toString(), 'Ошибка запроса');
            }
        },
        pdfLoadHead243(formData243){
            try {
                Ax(
                    {
                        url: '/api-py/upload-h243',  // Заголовок файла
                        method: 'POST',
                        data: formData243
                    },
                    (data) => {
                        // запуск обновления данных о пользователе в общем хранилище
                        // возврат на предыдущую страницу
                        this.busy3 = true
                        this.fileHead243 = JSON.parse(data);
                        this.fileProp243.name = this.fileHead243[0].fileName;
                        this.fileProp243.data = this.fileHead243[0].data;
                        this.fileProp243.mesuare = this.fileHead243[0].mesuare;
                        this.fileProp243.region = this.fileHead243[0].region;
                        this.fileProp243.period = this.fileHead243[0].period;
                        this.fileProp243.kod_profit = this.fileHead243[0].kod_profit;
                        this.pdfLoadBody243(formData243);
                    },
                    (error) => {
                        this.error = error;
                        this.busy3 = false;
                    },
                    this.busy3 = false
                );
            } catch (err) {
                this.busy3 = false;
                this.makeToast('danger', err.toString(), 'Ошибка запроса');
            }

        },
        pdfLoadBody243(formData243){
            try {
                Ax(
                    {
                        url: '/api-py/upload-b243', //  Тело файла
                        method: 'POST',
                        data: formData243
                    },
                    (data) => {
                        this.busy3 = true;
                        this.fileBody243 = JSON.parse(data);
                        this.busy3 = false;
                    },
                    (error) => {
                        this.error = error;
                        this.busy3 = false;
                    }
                );
            } catch (err) {
                this.busy3 = false;
                this.makeToast('danger', err.toString(), 'Ошибка запроса');
            }


        },
        inputFile552() {
            const formData552 = new FormData();
            if (this.file552 !== null) {
                formData552.append('file', this.file552, this.file552.name);
                this.File552Load(formData552);
            }
        },
        async File552Load (formData552) {
            try {
                const response = await fetch('/api-py/check-h552', {
                    method: 'POST',
                    credentials: 'include',
                    body: formData552
                });
                const result = await response.json();
                console.log(result);
                if (result === true) {
                    await this.pdfLoadHead552(formData552);
                }
                else {
                    this.busy4 = false;
                    this.makeToast('danger', 'Выбранный файл не соответствует шаблону!', 'Сообщение');
                }
            } catch (err) {
                this.makeToast('danger', err.toString(), 'Ошибка запроса');
            }
        },
        pdfLoadHead552(formData552){
            try {
                Ax(
                    {
                        url: '/api-py/upload-h552',  // Заголовок файла
                        method: 'POST',
                        data: formData552
                    },
                    (data) => {
                        this.busy4 = true;
                        this.fileHead552 = JSON.parse(data);
                        this.fileProp552.name = this.fileHead552[0].fileName;
                        this.fileProp552.data = this.fileHead552[0].data;
                        this.fileProp552.vid = this.fileHead552[0].vid;
                        this.fileProp552.region = this.fileHead552[0].region;
                        this.fileProp552.source = this.fileHead552[0].source;
                        this.fileProp552.spec = this.fileHead552[0].spec;
                        this.pdfLoadBody552(formData552);
                    },
                    (error) => {
                        this.error = error;
                        this.busy4 = false;
                    },
                    this.busy4 = false
                );
            } catch (err) {
                this.busy4 = false;
                this.makeToast('danger', err.toString(), 'Ошибка запроса');
            }
        },
        pdfLoadBody552(formData552){
            try {
                Ax(
                    {
                        url: '/api-py/upload-b552', //  Тело файла
                        method: 'POST',
                        data: formData552
                    },
                    (data) => {
                        this.busy4 = true;
                        this.fileBody552 = JSON.parse(data);
                        this.busy4 = false;
                    },
                    (error) => {
                        this.error = error;
                        this.busy4 = false;
                    }
                );
            } catch (err) {
                this.busy4 = false;
                this.makeToast('danger', err.toString(), 'Ошибка запроса');
            }

        },
        keyEvent(event) {
        },
        formClear(filt) {
            this.file1 = filt;
            this.fileBody = [];
        },
        formClear5(filt) {
            this.file5 = filt;
            this.fileBody5 = [];
        },
        formClear219(filt) {
            this.file219 = filt;
            this.fileBody219 = [];
        },
        formClear243(filt) {
            this.file243 = filt;
            this.fileBody243 = [];
        },
        formClear552(filt) {
            this.file552 = filt;
            this.fileBody552 = [];
        },
        saveLoad(param) {
            this.busy1 = param;
        },
        saveLoad5(param) {
            this.busy5 = param;
        },
        saveLoad219(param) {
            this.busy2 = param;
        },
        saveLoad243(param) {
            this.busy3 = param;
        },
        saveLoad552(param) {
            this.busy4 = param;
        },
        makeToast (variant = null, tostbody, title) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                autoHideDelay: 2000,
                solid: true
            })
        }
    },
    computed: {
    }
};
</script>

<style scoped>

</style>