






import { Component, Vue } from 'vue-property-decorator';
// import CCombineChart from '@/modules/program-monitor2/components/efficiency/c-combine-chart.vue';

@Component({
    name: 'c-prog-rating',
    components: {
        //  'c-combine-chart': CCombineChart
    }
})
export default class CProgRatimg extends Vue {
}
