<template>
    <div class="table-container">
            <div class="py-2 px-3">
                <b-button variant="success" @click="onRefresh()">Обновить</b-button>
            </div>
            <b-table id="my-table-body" sticky-header bordered head-variant="light" :items="journal"
                 :fields="fieldJ"
                 :table-variant="light">
            <template #cell(code)="row">
                <div align="center" class="code_text" >
                    {{ row.item.username }}
                </div>
            </template>
            <template #cell(code)="row">
                <div align="center" class="code_text">
                    {{ row.item.file_path }}
                </div>
            </template>
            <template #cell(code)="row">
                <div align="center" class="code_text" >
                    {{ row.item.file_name }}
                </div>
            </template>
            <template #cell(name)="row">
                <div align="left" class="code_text" >
                    {{ row.item.date_upload }}
                </div>
            </template>
            <template #cell(code)="row">
                <div align="center" class="code_text" >
                    {{ row.item.date_report }}
                </div>
            </template>
            <template #cell(code)="row">
                <div align="center" class="code_text" >
                    {{ row.item.description }}
                </div>
            </template>
            <template #cell(region)="row">
                <div align="center" class="code_text" >
                    {{ row.item.cnt }}
                </div>
            </template>
            <template #cell(code)="row">
                <div align="center" class="code_text" >
                    {{ row.item.error }}
                </div>
            </template>
        </b-table>
    </div>
</template>

<script>
export default {
    name: 'JournalTable',
    data() {
        return {
            light: 'light',
            journal: [],
            fieldJ: [
                { key: 'username', label: 'Пользователь', sortable: false },
                { key: 'file_path', label: 'Загружаемый файл', sortable: false },
                { key: 'file_name', label: 'Наименование  отчета', sortable: false },
                { key: 'date_upload', label: 'Дата загрузки', sortable: false },
                { key: 'date_report', label: 'Дата формирования отчета', sortable: false },
                { key: 'description', label: 'Статус загрузки', sortable: false },
                { key: 'cnt', label: 'Кол-во загруженных записей', sortable: false },
                { key: 'erorr', label: 'Ошибка', sortable: false }
            ]
        };
    },
    mounted() {
        this.loadJournal();
    },
    methods: {
        async loadJournal() {
            try {
                const response = await fetch('/api-py/journal');
                this.journal = await response.json();
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadJournal()', error.toString());
            }
        },
        onRefresh(event) {
            this.loadJournal();
        },
        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }
    }
};
</script>