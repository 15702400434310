




































































import { Vue, Component, Prop } from 'vue-property-decorator';
import CProgPie from '@/modules/program-monitor2/components/charts/c-program-variable-pie.vue';


@Component({
    name: 'c-widg-chart',
    components: {
        'c-prog-pie': CProgPie
    }
})
export default class CChartWidget extends Vue {
    @Prop({
        required: true,
        default: null
    })
    private data!: any[] | null;

    @Prop({
        required: true,
        default: []
    })
    private color!: string[];

    @Prop({
        required: true,
        default: ''
    })
    private title!: string;

    @Prop({
        required: false,
        default: null
    })
    private all!: string | null;

    @Prop({
        type: String,
        required: false,
        default: ''
    })
    public readonly download!: string;

    @Prop({
        required: false,
        default: function () {
            return [];
        }
    })
    private commentText!: string[];

    @Prop({
        required: false,
        default: 1
    })
    public readonly reflowTrig!: number;

    private commentModalShow = false;
    private chartAction = '';

    private fullSize = false;

    private fullSizible() {
        this.fullSize = !this.fullSize;
        this.$root.$emit('fullSizeActive');
        const cards = document.getElementsByClassName('.col:not(.full-size)');

        if (this.fullSize === true) {
            for (const el of cards) {
                el.setAttribute('style', 'display: none');
            }
        } else {
            for (const el of cards) {
                el.setAttribute('style', 'display: block');
            }
        }
    }
}
