


































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import Multiselect from 'vue-multiselect';

@Component({
    components: {
        multiselect: Multiselect
    },
    name: 'c-prog-pasport'
})
export default class ProgramPasport extends Vue {
    @Prop({
        required: true,
        default: { id: -1, name: '' }
    })
    private curProg!: any;

    private pasportData: any | null = null;

    private downloadHref = '';

    private fileLst: any[] = [];
    private curFileLst: any | null = null;


    private get pasport(): any {
        this.downloadHref = '';
        if (this.pasportData === null) {
            return null;
        }
        const result = Object.assign({ targetLst: [] }, this.pasportData);
        this.loadFileLst();
        let timing = '';
        if (this.pasportData.startDate !== null) {
            timing = (new Date(this.pasportData.startDate)).getFullYear().toString();
        }
        if (this.pasportData.endDate !== null) {
            timing += '-' + (new Date(this.pasportData.endDate)).getFullYear().toString();
        }
        result.timing = timing;
        if (this.pasportData.target !== null) {
            result.targetLst = this.pasportData.target.split('\n');
        }
        return result;
    }

    private async loadFileLst() { // загрузка списка файлов
        this.fileLst = [];
        this.curFileLst = null;
        const result = await fetch('/api/program-passport/file?passportId=' + this.pasportData.id).then(response => response.json());
        if (result !== null) {
            result.sort((a: any, b: any) => a.date - b.date);
            for (const el of result) {
                const obj = Object.assign({
                    href: '/api/program-passport/file/' + el.id + '/' + el.name,
                    value: el.id
                }, el);
                obj.text = this.dateFormat(new Date(el.date));
                this.fileLst.push(obj);
            }
            if (this.fileLst.length > 0) {
                this.curFileLst = this.fileLst[0];
            }
        }
    }

    private dateFormat(date: Date): string {
        return new Intl.DateTimeFormat(this.$i18n.locale, {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
        }).format(date);
    }

    private mounted() {
        this.loadPass();
        this.$watch('curProg', this.loadPass);
    }

    private async loadPass() {
        this.pasportData = null;
        let result: null | any = null;
        try {
            result = await fetch('/api/program-passport?programId=' + this.curProg.id)
                .then(response => response.json());
        } catch (e) {
            result = null;
            // eslint-disable-next-line
            console.log(this.curProg, e);
        }
        this.pasportData = result;
    }
}
