<template>
    <div class="v-card">
        <div v-if="header" class="header">
            <div class="title">
                <img :src="imgSrc"/>
                <span>{{ header }}</span>
            </div>
            <div class="btn-comment">

            </div>
        </div>
        <div class="body"><slot >Пока пусто</slot></div>

    </div>
</template>

<script>
import '../BudgetView.scss'

export default {
    name: 'GroupContainer',
    props: {
        imgSrc: String,
        header: String
    }
}
</script>

<style scoped>

</style>