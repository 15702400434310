<template>
    <b-container fluid="true">
        <div :class="!isChild ? 'receipt-row':'receipt-row-child'" v-bind:style="rowStyle">
            <div v-if="isChild"  class="receipt-row-action-child">

            </div>
            <div class="receipt-row-point">
                <div class="receipt-row-point-point" v-bind:style="{backgroundColor: color}"> </div>
            </div>
            <div class="receipt-row-code">{{ code }}</div>
            <div class="receipt-row-progress">
                <b-progress height="100%" show-progress v-bind:style="{backgroundColor: backgroundColor}">
                    <b-progress-bar :value="value" v-bind:style="{backgroundColor: color}">
                        <span>{{ text }}</span>
                    </b-progress-bar>
                </b-progress>
            </div>
            <div class="receipt-row-value">{{dataValue}}</div>
            <div v-if="!isChild" class="receipt-row-action" @click="expand()">
                <b-icon-chevron-up v-if="expanded" icon="chevron-bar-up" ></b-icon-chevron-up>
                <b-icon-chevron-down v-else icon="chevron-bar-down"></b-icon-chevron-down>
            </div>
            <div v-else class="receipt-row-action-child">

            </div>
        </div>
    </b-container>
</template>

<script>

import { BIconChevronDown, BIconChevronUp } from 'bootstrap-vue';

export default {
    name: 'ReceiptsGraphRow',
    components: { BIconChevronDown, BIconChevronUp },
    props: {
        value: {
            type: Number,
            default: 85
        },
        text: {
            type: String,
            default: 'No text'
        },
        code: {
            type: String,
            default: 'No code'
        },
        dataValue: {
            type: Number,
            default: 0
        },
        color: {
            type: String,
            default: '#42a5f5'
        },
        backgroundColor: {
            type: String,
            default: '#d9edfd'
        },
        isChild: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            expanded: false
        };
    },
    methods: {
        expand() {
            this.expanded = !this.expanded;
            if (this.expanded) {
                this.$emit('collapse-on');
            } else {
                this.$emit('collapse-off');
            }
        }
    },
    computed: {
        progress: function () {
            return {
                backgroundColor: this.backgroundColor
            };
        },
        progressBar: function () {
            return {
                backgroundColor: this.color
            };
        },
        rowStyle() {
            const st = {
                color: this.color
            };
            if (this.isChild) {
                st.margin = '40';
            }
            return st;
        }
    }
};
</script>

<style scoped lang="scss">

$row-height: 40px;
$row-top-padding: 8px;
//$main-color: red;
.receipt-row {
    //color: $main-color;
    min-height: $row-height;
    max-height: $row-height;
    box-shadow: 10px 11px 9px -3px #D7DAE0;
    background-color: #fff;
    margin-bottom: 5px;
    margin-top: 5px;
    display: flex;

    & .receipt-row-point {
        float: left;
        border-right: #bfbfbf inset thin;
        width: $row-height;
        padding: $row-top-padding;
        height: $row-height;
        & .receipt-row-point-point {
            margin-top: 6px;
            margin-left: 3px;
            width: 10px;
            height: 10px;
            border-radius: 5px;
        }
    }

    & .receipt-row-code {
        float: left;
        border-right: #bfbfbf inset thin;
        min-width: $row-height*3;
        padding: 6px;
        vertical-align: middle;
        height: $row-height;
        text-align: center;
        font-size: large;
        font-weight: 600;
    }

    & .receipt-row-progress {
        border-right: #bfbfbf inset thin;
        padding: calc($row-top-padding / 3);
        height: $row-height;
        width: 100%;

        & .progress {
            :last-child {
                //background-color: $main-color;
                font-size: large;
                text-align: left;
                padding-left: 20px;
                color: black;
                text-transform: uppercase;
                overflow: visible;
            }
        }
    }
    & .receipt-row-value {
        border-right: #bfbfbf inset thin;
        min-width: $row-height*4;
        height: $row-height;
        padding: $row-top-padding;
        text-align: right;
        float: right;
        font-size: large;
        font-weight: 600;

    }

    & .receipt-row-action {
        border-right: #bfbfbf inset thin;
        width: $row-height;
        height: $row-height;
        padding: $row-top-padding;
        float: right;
        color: white;
        background-color: #7c9db3;
    }
    & .receipt-row-action-child {
        background-color: #eef1f8;
        width: $row-height;
        height: $row-height;
        padding: $row-top-padding;
        float: right;
        opacity: 1;
    }
}
.receipt-row-child {
    @extend .receipt-row;
    box-shadow: 0px 0px 0px -3px #D7DAE0;
}
</style>